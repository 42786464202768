import React, { useState, useEffect }  from 'react';

// Needed 3rd party libraries
import { useParams, Link  } from 'react-router-dom';
import parse from "html-react-parser";
import dayjs from 'dayjs'

// Prime React Components
import { Image } from 'primereact/image';

// Needed Components / Images / Constants
import Subheader from '../components/Subheader'
import AboutMenu from '../components/AboutMenu';
import { PAGE_DETAILS, GRANTS_NOTICE } from '../constants/pageConfig';

import AboutImg1 from '../images/about-img1.jpg';
import AboutImg2 from '../images/about-img2.jpg';
import AboutImg3 from '../images/about-img3.jpg';

const About = (props) => {
	const { aboutus } = props
	let { selectedAbout } = useParams();
	const objAbout = PAGE_DETAILS['/about'] || {}
	const arrCategories = objAbout.items || []
	const objCategory = arrCategories.find(cur => cur.link === '/' + selectedAbout) || {}
	console.log(objAbout)
	console.log(arrCategories)
	console.log(objCategory)
	console.log(selectedAbout)
	console.log(aboutus)

	return (
        <div className="flex flex-column about-main">
            <Subheader
                title="About Us"
                category={objCategory.name}
                description="Neurelis is committed to furthering the goals of various professional, charitable, educational, and patient-focused organizations. In accordance with applicable laws and industry codes of conduct, Neurelis provides support for initiatives that seek to advance medical education, research, and outreach to patients, families, and healthcare providers through external funding opportunities."
            />
            <div className="flex page flex-column lg:flex-row">
                <div className="desktopOnly">
                    <AboutMenu selectedAbout={selectedAbout} arrCategories={arrCategories} />
                </div>
                { !objCategory.name &&
                    <div className="flex mobileOnly">
                        <AboutMenu selectedAbout={selectedAbout} arrCategories={arrCategories} />
                    </div>
                }
                    <div className="flex flex-column" style={{width: '100%'}}>
                        <div>
                            <h1 className="font-bold title">Neurelis Clinical Development and Medical Affairs</h1>
                            <p className="text-xl mb-3 subtitle">Scientific Communications | Medical Education | Clinical Support</p>

                            <div className="flex flex-column gap-5 flex-wrap flex-auto mt-5 mb-5">
                                <div className="flex align-items-center flex-1 flex-row about-card mb-4 flex-column md:flex-row">
                                    <div className="flex-1">
                                        <Image className="block w-full about-card-img" src={AboutImg1} alt="Image" width="300" />
                                    </div>
                                    <div className="flex-1 bg-white about-content p-5">
                                    <p className="text-base">At Neurelis, we are inspired by the stories of patients living with epilepsy and disorders of the central nervous system.</p>

                                    <p className="text-base">Together with our strategic partners, we are dedicated to enhancing therapeutic benefits and providing a differentiated approach to addressing significant unmet needs in patient care.</p>
                                    </div> 
                                </div>
                                <div className="flex align-items-center flex-1 flex-row about-card mb-4 flex-column md:flex-row">
                                    <div className="flex-1">
                                        <Image className="block w-full about-card-img" src={AboutImg2} alt="Image" width="300" />
                                    </div>
                                    <div className="flex-1 bg-white about-content p-5">
                                        <h2 className="font-bold">Who We Are</h2>
                                        <p className="text-lg">Neurelis Clinical Development & Medical Affairs is a neuroscience-based medical and scientific team of clinical healthcare professionals dedicated to communicating the science surrounding our technologies. Through an integrated approach to clinical research, education, publications, and pharmacovigilance, our clinical research and field-based teams are focused on empowering–and learning from–healthcare providers, patients, and care partners.</p>
                                    </div> 
                                </div>
                                <div className="flex align-items-center flex-1 flex-row about-card mb-4 flex-column md:flex-row">
                                    <div className="flex-1">
                                        <Image className="block w-full about-card-img" src={AboutImg3} alt="Image" width="300" />
                                    </div>
                                    <div className="flex-1 bg-white about-content p-5">
                                        <h2 className="font-bold">Neurelis Drug Delivery Technology</h2>
                                        <p className="text-lg">Since 2007, Neurelis has been dedicated to addressing unmet clinical needs. To this end, we are pursuing delivery technologies with the goal of enhancing drug development and clinically relevant benefits for patients. We leverage these technologies to create and enhance drug therapies, with a focus on entral nervous system (CNS) indications.</p>
                                        <p className="text-lg">Through our unique portfolio of product candidates and platform technologies, we aim to help patients regain normalcy and live safer and healthier lives.</p>
                                    </div> 
                                </div>
                            </div>

                        </div>
                    </div>
            </div>
        </div>








    );
}

export default About;