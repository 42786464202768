// Auth
export const GET_USER_AUTH = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/login"
export const REGISTER_USER_AUTH = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/register"
export const UPDATE_USER = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/updateUser"
export const RESET_PASSWORD = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/resetPassword"
export const UPDATE_PASSWORD = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/updatePassword"
export const CHECK_UUID = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/checkUUID"

// Entities
export const GET_RESOURCES = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/getResources"
export const GET_MSL = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/getMSL"
export const GET_PODCASTS = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/getPodcasts"
export const GET_EVENTS = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/getEvents"
export const GET_HOMEPAGE_IMAGES = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/getNeurelisHomepageImages"

// PDF
export const GET_PDF = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/getPDF"
export const CHECK_PDF = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/checkPDF"

// CONTACT US
export const CONTACT_US = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/contactUs"
export const MIRF_REQUEST = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/mirf"
export const FOLLOW_UP = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/followUp"

// SEARCH 
export const SEARCH = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/search"
export const AUTOCOMPLETE = "/rest/" + process.env.REACT_APP_RESTPATH + "/v1/autoComplete"



