import { createRoot } from 'react-dom/client';
import { BrowserRouter  } from "react-router-dom";
import 'primereact/resources/primereact.min.css';
import 'primeflex/primeflex.scss';
import 'primeicons/primeicons.css'

import './app.scss';
import { AuthProvider } from "./hooks/useAuth";
import App from './App';

import ReactGA from "react-ga4";

ReactGA.initialize("G-643KR69CB9");

const root = createRoot(document.getElementById('root'));



root.render(
	<BrowserRouter >
		<AuthProvider>
			<App />
		</AuthProvider>
	</BrowserRouter >
);

