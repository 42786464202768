import React, { useState, useEffect, useRef }  from 'react';

// Needed 3rd party libraries

// Prime React Components
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { InputMask } from 'primereact/inputmask';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';

// Needed Components / Images / Constants
import Subheader from '../components/Subheader'
import { STATE_DROPDOWN, CREDENTIALS_DROPDOWN} from "../constants/registerUserData"
import { useAuth } from "../hooks/useAuth";


const MyAccount = () => {
	const toast = useRef(null);
	const user = JSON.parse(localStorage.getItem("user"));
	const { updateMyAccount } = useAuth();

	const [ submitted, setSubmitted ] = useState(false);
	const [ucred, setCred] = useState(user.userDetails.credentials || '');
	const [unpi, setNpiNumber] = useState(user.userDetails.npi_number || '');
	const [ulicense, setLicense] = useState(user.userDetails.license_number || '');
	const [ufirstname, setFirstName] = useState(user.userDetails.first_name || '');
	const [ulastname, setLastName] = useState(user.userDetails.last_name || '');
	const [uinit, setInit] = useState(user.userDetails.institution || '');
	const [uemail, setEmail] = useState(user.userDetails.email || '');
	const [uphone, setPhone] = useState(user.userDetails.phone || '');
	const [ustate, setState] = useState(user.userDetails.state || '');
	const [hasErrors, setHasErrors] = useState(false);
	
	

	const fncRegisterValidate = async (event) => {
		setSubmitted(true);
		let errors = []
		if (!ucred.trim()) errors.push('credentials')
		if (!ufirstname.trim()) errors.push('first_name')
		if (!ulastname.trim()) errors.push('last_name')
		if (!uinit.trim()) errors.push('institution')
		if (!uemail.trim()) errors.push('email')
		if (!ustate.trim()) errors.push('state')
		if (!uphone.trim()) errors.push('phone')
		
		// We have errors, display correct message and leave
		if (errors.length > 0) {
			setHasErrors(true)
			return
		}

		setHasErrors(false)
		let data = {
          'credentials': ucred,
          'npi_number': unpi,
          'license_number': ulicense,
          'first_name': ufirstname,
          'last_name': ulastname,
          'institution': uinit,
          'email': uemail,
          'phone': uphone,
          'state': ustate,
        }
      	let results = await updateMyAccount(data);
		if (results) {
			toast.current.show({severity:'success', summary: 'Success', detail: 'Your Account has been updated.', life: 3000});
			setSubmitted(false);
		} else {
			toast.current.show({severity:'error', summary: 'Error', detail: 'There was a problem updating your account.', life: 3000});
		}
		
	}

	return (
		 <div className="flex flex-column">
			<Subheader 
				title="My Account" 
			/>

			<div className="flex page my-account flex-column md:flex-row">
			
				<div className="flex flex-column inputForm py-5 px-3 border-1 border-primary">
					<form>
						<Toast ref={toast} />
						
						
						<div className="grid">
							<div className="col-12 md:col-4 xl:col-4 mb-2">
								<div className="field fullField"  >
									<span className="p-float-label">
										<InputNumber 
											id="npi_number" 
											useGrouping={false}
											value={unpi} 
											onValueChange={(e) => setNpiNumber(e.value)}
											required 
											className="p-inputnumber"
										/>
										<label htmlFor="npi_number">NPI Number</label>
									</span>
									
								</div>
							</div>
							<div className="col-12 md:col-4 xl:col-4 mb-2">
								<div className="field fullField"  >
									<span className="p-float-label">
										<InputNumber 
											id="license_number" 
											value={ulicense} 
											useGrouping={false}
											onValueChange={(e) => setLicense(e.value)}
											required 
											className="p-inputnumber"
										/>
										<label htmlFor="license_number">License Number</label>
									</span>
									
								</div>
							</div>
							<div className="col-12 md:col-4 xl:col-4 mb-2">
								<div className="field fullField"  >
									<span className="p-float-label">
										<Dropdown 
											// value={registerData.credentials} 
											value={ucred}
											onChange={(e) => setCred((prev) => (prev = e.value))}
											options={CREDENTIALS_DROPDOWN} 
											className={classNames({ 'p-invalid': submitted && !ucred })} 
										/>
										<label htmlFor="credentials">Credentials</label>
									</span>
									{submitted && !ucred && <small className="p-error">Credentials are required.</small>}
								</div>
							</div>

							<div className="col-12 md:col-6 xl:col-6 mb-2">
								<div className="field fullField"  >
									<span className="p-float-label">
										<InputText 
											id="first_name" 
											value={ufirstname} 
											onChange={(e) => setFirstName(e.target.value)}
											required 
											className={classNames('p-inputtext', { 'p-invalid': submitted && !ufirstname })} 
										/>
										<label htmlFor="first_name">First Name</label>
									</span>
									{submitted && !ufirstname && <small className="p-error">First Name is required.</small>}
								</div>
							</div>

							<div className="col-12 md:col-6 xl:col-6 mb-2">
								<div className="field fullField"  >
									<span className="p-float-label">
										<InputText 
											id="last_name" 
											value={ulastname}
											onChange={(e) => setLastName(e.target.value)}
											required 
											className={classNames('p-inputtext', { 'p-invalid': submitted && !ulastname })} 
										/>
										<label htmlFor="last_name">Last Name</label>
									</span>
									{submitted && !ulastname && <small className="p-error">Last Name is required.</small>}
								</div>
							</div>

							<div className="col-12 mb-2">
								<div className="field fullField">
									<span className="p-float-label">
										<InputText 
											id="institution" 
											value={uinit} 
											onChange={(e) => setInit(e.target.value)}
											required 
											className={classNames('p-inputtext', { 'p-invalid': submitted && !uinit })} 
										/>
										<label htmlFor="institution">Institution</label>
									</span>
									{submitted && !uinit && <small className="p-error">Institution is required.</small>}
								</div>
							</div>

							<div className="col-12 md:col-6 xl:col-6 mb-2">
								<div className="field fullField"  >

									<span className="p-float-label">
										<InputText 
											id="email" 
											value={uemail} 
											onChange={(e) => setEmail(e.target.value)}
											required 
											autoComplete="email"
											className={classNames('p-inputtext', { 'p-invalid': submitted && !uemail })} 
										/>
										<label htmlFor="email">Email</label>
									</span>
									{submitted && !uemail && <small className="p-error">Email is required.</small>}
								</div>
							</div>

							<div className="col-12 md:col-6 xl:col-6 mb-2">
								<div className="field fullField"  >

									<span className="p-float-label">
										<InputMask 
											id="phone"
											value={uphone} 
											onChange={(e) => setPhone(e.target.value)} 
											mask="(999) 999-9999" 
											required
											autoComplete="phone"
											className={classNames('p-inputtext', { 'p-invalid': submitted && !uphone })} 
										/>
										<label htmlFor="phone">Phone Number</label>
									</span>
									{submitted && !uphone && <small className="p-error">Phone is required.</small>}
								</div>
							</div>

					
							<div className="col-12 mb-2">
								<div className="field fullField"  >
									<span className="p-float-label">
										<Dropdown 
											value={ustate}
											onChange={(e) => setState((prev) => (prev = e.value))} 
											options={STATE_DROPDOWN} 
											className={classNames({ 'p-invalid': submitted && !ustate })} 
										/>
										<label htmlFor="state">State</label>
									</span>
									{submitted && !ustate && <small className="p-error">State is required.</small>}
								</div>
							</div>
						</div>
						{hasErrors && 
							<div className="flex  justify-content-center red mb-2 boldText" >
								Please fill in all required fields.
							</div>
						}
						<div className="flex justify-content-center">
							<div className="submitButton flex align-items-center justify-content-center" onClick={fncRegisterValidate}>
								Update
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
    );
}

export default MyAccount;