import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation  } from 'react-router-dom';

import axios from 'axios';

// Prime React Components
import { Button } from 'primereact/button';

// Needed Components
import { SEARCH } from '../constants/URLS';
import Subheader from '../components/Subheader'
import SearchMenu from '../components/SearchMenu';
import { useAuth } from "../hooks/useAuth";
import { fncGetPDF } from "../services/PDFService"

import ReactGA from "react-ga4";

const Search = (props) => {
	const { fncDisplayLoginPanel } = props;
	const { loggedIn } = useAuth();
	const isLoggedIn = loggedIn() 
	const navigate = useNavigate();
	const search = useLocation().search;
	const s = new URLSearchParams(search).get('s');
	const [searchResults, setSearchResults] = useState([]); 
	const [searchTerm, setSearchTerm] = useState(s);
	const [selectedCategories, setSelectedCategories ] = useState([])
	
	
	useEffect(() => {
		fncSearch()
    }, [searchTerm, selectedCategories]); 
	
	const fncSearch = async () => {
		if (searchTerm.length < 3) {
			setSearchResults([])
			return
		} 
		let urlCategories = ''
		if (selectedCategories.length > 0) {
			urlCategories = '&subcategory=' + selectedCategories.map(cur => cur.key).join(',')
		} 

		ReactGA.event(
			"view_search_results", { "search_term":searchTerm.toLowerCase()} 
		  );	

		let searchXHR = await axios({method: 'get', url: SEARCH + '?searchTerm=' + searchTerm.toLowerCase() + urlCategories})
		setSearchResults(searchXHR.data.results)
	}
	
	const fncSearchSelect = async (rowData) => {

		if (rowData.document_type === 'html' || rowData.document_type === 'external') {
			window.open(rowData.url,'_blank')

			ReactGA.event(
				"search_results_autocomplete", { "url":rowData.url} 
			  );


		} else if (rowData.document_type === 'page') {
			let rowData = new URL(rowData.url)
			navigate(rowData.pathname)

			ReactGA.event(
				"search_results_autocomplete", { "page":rowData.pathname} 
			  );


		} else {
			//rowData.searchID
			let results = await fncGetPDF(rowData.searchID)

			ReactGA.event(
				"search_results_autocomplete", { "pdf":rowData.searchID} 
			  );

			if (results === 'no-token') {
				fncDisplayLoginPanel('register')
			}
		}

	}
	
	return (
        <div className="flex flex-column">
			<Subheader 
				title="Search Medical Affairs" 
				fncSearch={setSearchTerm}
				searchTerm={searchTerm}
			/>

			<div className="flex page searchPage flex-column md:flex-row mb-5">
				<div>
					<SearchMenu 
						selectedCategories={selectedCategories} 
						setSelectedCategories={setSelectedCategories}
					/>
				</div>
				<div className="flex flex-column mr-4" style={{width: "100%"}}>
					<div>
						{searchResults.map((cur, index) => {
							return (
								<div key={index} className="flex flex-column searchItem pb-2 link " onClick={(e) => fncSearchSelect(cur)}>
									<div className="flex justify-content-between ">
										<div className="flex flex-column mt-2 mb-2">
											<div className="flex ">
												<div>
													{cur.locked === 1 && !isLoggedIn &&
														<i className="pi pi-lock mr-2"/>
													}
													{cur.locked === 2 && !isLoggedIn &&
														<i className="pi pi-lock red mr-2"/>
													}
													{cur.locked === 1 && isLoggedIn &&
														<i className="pi pi-unlock mr-2" />
													}
													{cur.locked === 2 && isLoggedIn &&
														<i className="pi pi-unlock red mr-2" />
													}
												</div>
												<div className="flex ">
													<div className="capitalize">
														{cur.category}
													</div>
													{cur.subcategory !== "" && 
														<div className="pl-2 capitalize">
															- {cur.subcategory}
														</div>
													}
													
												</div>
											</div>
											<div className="title">
												{cur.title}
											</div>
										</div>
										<div className="pl-4">
											<i className="pi pi-arrow-up-right link" />
										</div>
									</div>
									
								</div>
							)
						})}
					</div>
					{!isLoggedIn && searchResults.length > 0 && 
						<div className="flex flex-column align-items-center justify-content-center loginBox">
							<div className="loginMessage">
								Your search has returned {searchResults.length} documents, please log in or register to see remaining results.
							</div>
							<div className="flex headerButtons desktopOnly">
								<div className="mr-2 ">
									<Button text severity="secondary" onClick={(e) => fncDisplayLoginPanel('login')}>
										<i className="pi pi-sign-in flipIcon mr-1" />
										Login
									</Button>
								</div>
								<div>
									<Button severity="secondary" icon="pi pi-user" label="Register"  outlined onClick={(e) => fncDisplayLoginPanel('register')} />
								</div>
							</div>
						</div>
					}


					{searchResults.length === 0 && 
						<div className="flex flex-column align-items-center justify-content-center loginBox">
							<div className="loginMessage">
								Your search did not produce any results. Please try another search.
							</div>
							
						</div>
					}


				</div>
				
			</div>
		</div>
    );
}

export default Search;