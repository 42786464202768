import React, { useState, useEffect } from "react";
import { Carousel } from 'primereact/carousel';
import { Link, useNavigate } from 'react-router-dom';

const HomeSlider = (props) => {
	const navigate = useNavigate();
	const {neurelisImages} = props
	const scrollMS = 6000
	const [ autoPlaySpeed, setAutoPlaySpeed] = useState(scrollMS);


    const responsiveOptions = [
        {
            breakpoint: '1199px',
            numVisible: 1,
            numScroll: 1
        }
    ];

	const products = neurelisImages.map(cur => {
		return {
            altText: cur.altText,
			url: cur.url,
			openNewWindow: cur.openNewWindow,
            image: "/images/homePage/" + cur.photo
        }
	})

    const productTemplate = (product) => {
      
		if (product.url !== "" && product.url.indexOf('http') === 0 && product.openNewWindow === 1) {
			return (
				<div className="flex flex-row slider-outer">
					<div className="image w-full">
						<a href={product.url} rel="noopener noreferrer"  target="_blank">
							<img className="block w-full" src={product.image} title={product.altText} alt={product.altText} />
						</a>
					</div>
				</div>
			);
		}

		if (product.url !== "" && product.url.indexOf('http') === 0 && product.openNewWindow === 0) {
			return (
				<div className="flex flex-row slider-outer">
					<div className="image w-full">
						<a href={product.url} rel="noopener noreferrer">
							<img className="block w-full" src={product.image} title={product.altText} alt={product.altText} />
						</a>
					</div>
				</div>
			);
		}

		if (product.url !== "" && product.url.indexOf('http') === -1 && product.openNewWindow === 0) {
			return (
				<div className="flex flex-row slider-outer">
					<div className="image w-full link" onClick={(e) => navigate(product.url)}>
						<img className="block w-full" src={product.image} title={product.altText} alt={product.altText} />
					</div>
				</div>
			);
		}

		return (
            <div className="flex flex-row slider-outer">
                <div className="image w-full">
                    <img className="block w-full" src={product.image} title={product.altText} alt={product.altText} />
                </div>
            </div>
        );
    };

	return (
        <div >
			<div className="complaint-bar text-center">
                To report an adverse event or product complaint, call <b><Link to="tel:+18666963873">(866) 696-3873</Link></b>
            </div>
            <Carousel 
				value={products} 
				numScroll={1} 
				numVisible={1} 
				responsiveOptions={responsiveOptions} 
				itemTemplate={productTemplate} 
				autoplayInterval={autoPlaySpeed}
				circular
				onMouseOver={(e) => setAutoPlaySpeed(600000)} onMouseOut ={(e) => setAutoPlaySpeed(scrollMS)}
			/>
        </div>
    );
}

export default HomeSlider;