import React, { useState, useEffect, useRef } from 'react';

// Needed 3rd party libraries
import { Route, Routes } from "react-router-dom";
import axios from 'axios';


// Prime React Components
import { Toast } from 'primereact/toast';
import { Sidebar } from 'primereact/sidebar';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

// Internal Components, helpers, and constants
import { GET_RESOURCES, GET_MSL, GET_PODCASTS, GET_EVENTS, GET_HOMEPAGE_IMAGES } from './constants/URLS';
import { useAuth } from "./hooks/useAuth";

import Header from './layout/Header'
import Footer from './layout/Footer'
import Login from './layout/Login'
import Register from './layout/Register'

// Pages
import Home from './pages/Home'
import Resources from './pages/Resources'
import About from './pages/About'
import Grants from './pages/Grants'
import Events from './pages/Events'
import Pipeline from './pages/Pipeline'
import Contact from './pages/Contact'
import Search from './pages/Search'
import MyAccount from './pages/MyAccount'
import View from './pages/View'
import MedicalRequest from './pages/MedicalRequest'
import FollowUp from './pages/FollowUp'
import ResetPassword from './pages/ResetPassword'

import ReactGA from "react-ga4";

function App() {
	
	// REFERENCE VARIABLES
	const toast = useRef(null);

	// OTHER VARIABLES
	const registerDataShell = {
		credentials: '',
		first_name: '',
		last_name: '',
		institution: '',
		email: '',
		password: '',
		state: '',
		opt_in: ''
	}
	const { loggedIn, register, logout, login, resetPassword } = useAuth();
	loggedIn()

	// STATE VARIABLES
	const [resources, setResources] = useState([]); // Once we make AJAX Call, this will hold all the resources
	const [msl, setMsl] = useState([]); // Once we make AJAX Call, this will hold all the MLS
	const [podcasts, setPodcasts] = useState([]); // Once we make AJAX Call, this will hold all the Podcasts
	const [neurelisEvents, setNeurelisEvents] = useState([]); // Once we make AJAX Call, this will hold all the Events
	const [neurelisImages, setNeurelisImages] = useState([]); // Once we make AJAX Call, this will hold all the Images
	const [loginPanel, setLoginPanel] = useState(false); // Indicates if the login panel will show
	const [loginPanelType, setLoginPanelType] = useState('register'); 
	const [registerData, setRegisterData] = useState(registerDataShell); 
	const [ displayRedirectModal, setDisplayRedirectModal] = useState(false);

	// USE EFFECT HOOKS (fire when things change on the page)
	useEffect(() => {
        // This loads when the page loads and will get the resources from the database
		fncGetResources()
    }, []); 

	useEffect(() => {
        // This loads when the page loads and will get the programs from the database
		fncGetMSL()
    }, []); 

	useEffect(() => {
		// This loads when the page loads and will get the programs from the database
		if (!window.sessionStorage.getItem('viewedRedirect')) setDisplayRedirectModal(true)
    }, []); 

	useEffect(() => {
        // This loads when the page loads and will get the Podcasts from the database
		fncGetPodcasts()
    }, []); 

	useEffect(() => {
        // This loads when the page loads and will get the Events from the database
		fncGetEvents()
    }, []); 

	useEffect(() => {
        // This loads when the page loads and will get the Images from the database
		fncGetImages()
    }, []); 

	// ACTION / XHR FUNCTIONS
	const fncGetResources = async () => {
		let resourcesXHR = await axios({method: 'get', url: GET_RESOURCES})
		let _resources = {}
		if (resourcesXHR.data && resourcesXHR.data.success) {
			
			// Break up the resources by category/subcategory so can display different sections
			_resources = resourcesXHR.data.results.reduce((acc,cur) => {
				let strCategory = cur.category.toLowerCase()
				let strSubcategory = cur.subcategory.toLowerCase()
				let strSubcategoryYear = cur.subcategoryYear

				if (!acc[strCategory]) {
					acc[strCategory] = {
						name: cur.category,
						resources: [],
						subcategoriesByYear: {}
					}
				}
				
				if (strSubcategory !== '') { // we have subcategories
					
					if (!acc[strCategory].subcategoriesByYear[strSubcategory]) {
						acc[strCategory].subcategoriesByYear[strSubcategory] = {
							name:  cur.subcategory,
							years: {}
						}
					}

					if (!acc[strCategory].subcategoriesByYear[strSubcategory].years[strSubcategoryYear]) {
						acc[strCategory].subcategoriesByYear[strSubcategory].years[strSubcategoryYear] = {
							resources: []
						}
					}

					acc[strCategory].subcategoriesByYear[strSubcategory].years[strSubcategoryYear].resources.push(cur)
				} else { // we do not have any subcategories
					acc[strCategory].resources.push(cur)
				}
				return acc
			},{})
		} else {
			console.error('There was an error getting resource data.')
		}

		setResources(_resources)
	};

	const fncGetMSL = async () => {
		let mslXHR = await axios({method: 'get', url: GET_MSL})
		if (mslXHR.data && mslXHR.data.success) {
			setMsl(mslXHR.data.results)
		} else {
			console.error('There was an error getting msl data.')
		}
	};

	const fncGetPodcasts = async () => {
		let podcastXHR = await axios({method: 'get', url: GET_PODCASTS})
		if (podcastXHR.data && podcastXHR.data.success) {
			setPodcasts(podcastXHR.data.results)
		} else {
			console.error('There was an error getting podcast data.')
		}
	};

	const fncGetEvents = async () => {
		let eventsXHR = await axios({method: 'get', url: GET_EVENTS})
		if (eventsXHR.data && eventsXHR.data.success) {
			setNeurelisEvents(eventsXHR.data.results)
		} else {
			console.error('There was an error getting events data.')
		}
	};

	const fncGetImages = async () => {
		let imagesXHR = await axios({method: 'get', url: GET_HOMEPAGE_IMAGES})
		if (imagesXHR.data && imagesXHR.data.success) {
			setNeurelisImages(imagesXHR.data.results)
		} else {
			console.error('There was an error getting image data.')
		}
	};

	const fncDisplayLoginPanel = (panelType) => {


		if (panelType==='register') {
			ReactGA.event("Register Tag");
		} else {
			ReactGA.event("Login Tag");
		}

		setLoginPanel(true)
		setLoginPanelType(panelType)
	}

	const fncCloseDisplayLoginPanel = (panelType) => {
		setLoginPanel(false)
	}

	// OTHER FUNCTIONS
	const fncSetRegisterData = (key, value) =>{
		let _registerData = {...registerData}
		_registerData[key] = value
		setRegisterData(_registerData)
	}

	const fncClearRegisterData = () => {
		setRegisterData(registerDataShell)
	}

	const fncLogin = async (loginData) => {
		let results = await login(loginData)

		if (results === true) {
			await fncGetResources()
			await fncGetMSL()
			await fncGetPodcasts()
			await fncGetEvents()
   	 	}

		return results
	}

	const fncRegister = async () => {
		let results = await register(registerData)

		if (results === true) {
			await fncGetResources()
			await fncGetMSL()
			await fncGetPodcasts()
			await fncGetEvents()
   	 	}

		return results
	}

	const fncLogout = async () => {
		await logout(registerData)
		await fncGetResources()
		await fncGetMSL()
		await fncGetPodcasts()
		await fncGetEvents()
   	 	
	}

	const fncSendToastMessage = (severity, message) => {

		if (severity === 'success') {
			toast.current.show({ severity: 'success', summary: 'Successful', detail: message, life: 3000 });
		} else if (severity === 'error') {
			toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
		}
		
	}

	const fncRedirectClick = (blnRedirect) => {
		setDisplayRedirectModal(false)
		window.sessionStorage.setItem('viewedRedirect', 'true');
		if (blnRedirect) {

			ReactGA.event("Healthcare Professional Modal - No");



			document.location.href="https://neurelis.com/"
		} else {

			ReactGA.event("Healthcare Professional Modal - Yes");
			
		}
	}

	return (
	<div className="App">
				<Toast ref={toast} />
				<div className="flex flex-column">
					<Header fncDisplayLoginPanel={fncDisplayLoginPanel} fncLogout={fncLogout} />
				</div>

				<Routes>

					<Route path="/resources/:selectedCategory" element={<Resources resources={resources} msl={msl} podcasts={podcasts} fncDisplayLoginPanel={fncDisplayLoginPanel}  />} />
					<Route path="/resources/:selectedCategory/:selectedSubCategory" element={<Resources resources={resources} msl={msl} podcasts={podcasts} fncDisplayLoginPanel={fncDisplayLoginPanel}  />} />
					<Route path="/resources" element={<Resources resources={resources} fncDisplayLoginPanel={fncDisplayLoginPanel} />} />
					<Route path="/grants/:selectedGrant" element={<Grants />} />
					<Route path="/grants" element={<Grants />} />
					<Route path="/events" element={<Events neurelisEvents={neurelisEvents} />} />
					<Route path="/pipeline" element={<Pipeline />} />
					<Route path="/contact" element={<Contact />} />
					<Route path="/search" element={<Search fncDisplayLoginPanel={fncDisplayLoginPanel} />} />
					<Route path="/my-account" element={<MyAccount registerData={registerData} fncSetRegisterData={fncSetRegisterData} fncClearRegisterData={fncClearRegisterData} fncRegister={fncRegister} />} />
					<Route path="/about" element={<About />} />
					<Route path="/about/:selectedAbout" element={<About />} />
					<Route path="/view/:resourceID" element={<View />} />
					<Route path="/" element={<Home neurelisEvents={neurelisEvents} podcasts={podcasts} neurelisImages={neurelisImages} fncDisplayLoginPanel={fncDisplayLoginPanel}/>} />
					<Route path="/mirf" element={<MedicalRequest fncDisplayLoginPanel={fncDisplayLoginPanel} />} />
					<Route path="/request" element={<MedicalRequest fncDisplayLoginPanel={fncDisplayLoginPanel} />} />
					<Route path="/follow-up" element={<FollowUp fncDisplayLoginPanel={fncDisplayLoginPanel} />} />
					<Route path="/resetPassword/:uuid" element={<ResetPassword />} />
				</Routes>

				<Sidebar visible={loginPanel} position="right" onHide={() => setLoginPanel(false)} className="loginPanel w-full md:w-20rem lg:w-30rem">
					{(loginPanelType === 'register' ?
						<Register
							fncCloseDisplayLoginPanel={fncCloseDisplayLoginPanel}
							fncDisplayLoginPanel={fncDisplayLoginPanel}
							registerData={registerData}
							fncSetRegisterData={fncSetRegisterData}
							fncClearRegisterData={fncClearRegisterData}
							fncRegister={fncRegister}
					/> :
						<Login
							fncCloseDisplayLoginPanel={fncCloseDisplayLoginPanel}
							fncLogin={fncLogin}
							resetPassword={resetPassword}
							fncSendToastMessage={fncSendToastMessage}
					/>
					)}
				</Sidebar>

				<div>
					<Footer />
				</div>
				<Dialog className="redirectModal" visible={displayRedirectModal} onHide={() => setDisplayRedirectModal(false)} closable={false}>
					<div className="flex flex-column text-center ml-4 mr-4 pr-4 pl-4">
						<div className="title">
							Confirm you are a US Healthcare Professional
						</div>
						<div className="body">
							You are about to enter a site that is for US healthcare professionals only. By selecting "Yes" below, you certify that you are a healthcare professional and that you wish to proceed to the healthcare professionals only site.
						</div>
						<div className="flex justify-content-between buttons">
							<Button className="yesButton mr-4 w-full text-center" severity="primary" label="Yes" outlined onClick={(e) => {fncRedirectClick(false)}} />
							<Button className="noButton ml-4 w-full text-center" severity="secondary" label="No" outlined onClick={(e) => {fncRedirectClick(true)}} />
						</div>
					</div>
					

			    </Dialog>
			</div>
  );
}

export default App;
