import React from 'react';
import classNames from 'classnames';

const NeurelisIcon = (props) => {   
	const { icon, selected } = props

	return (
		<div className="neurelisIcons">
			<div className={"icon " + icon + (selected?'-white-color':'')} />
		</div>
    );
}

export default NeurelisIcon;