import React, { useState, useEffect }  from 'react';

// Needed 3rd party libraries
import { useParams, Link, useNavigate   } from 'react-router-dom';
import parse from "html-react-parser";
import dayjs from 'dayjs'
import classNames from 'classnames';

// Prime React Components

// Needed Components / Images / Constants
import Subheader from '../components/Subheader'
import ResourceMenu from '../components/ResourceMenu';
import Resource from '../components/Resource';
import { PAGE_DETAILS, RESOURCE_NOTICE } from '../constants/pageConfig';
import NeurelisMap  from '../images/map.png'

const Resources = (props) => {
	const { resources, msl, podcasts, fncDisplayLoginPanel } = props
	let { selectedCategory, selectedSubCategory } = useParams();
	const navigate  = useNavigate();
	const objResources = PAGE_DETAILS['/resources'] || {}
	const arrCategories = objResources.items || []
	const objCategory = arrCategories.find(cur => cur.link === '/' + selectedCategory) || {}
	
	// STATE VARIABLES
	const [ currentCategory, setCurrentCategory] = useState({});
	const [ currentSubcategory, setCurrentSubcategory] = useState(null);
	const [ currentResoures, setCurrentResoures] = useState([]);
	
	useEffect(() => {
		let _currentCategory = {}
		let _currentSubcategory = null
		let _resources = []
		if (resources[selectedCategory]) {
			_currentCategory = resources[selectedCategory]
			if (Object.keys(_currentCategory.subcategoriesByYear).length > 0) {
				let arrKeysOrdered = Object.keys(_currentCategory.subcategoriesByYear).sort((a,b) => (a > b?1:-1))
				let _subCatIndex = arrKeysOrdered.indexOf(selectedSubCategory?.toLowerCase())
				let defaultIndex = _subCatIndex > -1 ? _subCatIndex : 0
				_currentSubcategory = arrKeysOrdered[defaultIndex]
			if (_subCatIndex === -1) {
					navigate('/resources/' + selectedCategory + '/' + arrKeysOrdered[0], {replace: true})
				  }
				//_resources = _currentCategory.subcategories[_currentSubcategory].resources
			} else {
				_resources = _currentCategory.resources
			}
		} 
		
		

		setCurrentCategory(_currentCategory)
		setCurrentSubcategory(_currentSubcategory)
		setCurrentResoures(_resources)

    }, [resources, selectedCategory]); 

	const fncSetSubcat = (strSubCat) => {
		
		navigate('/resources/' + selectedCategory + '/' + strSubCat, {replace: true})
		setCurrentSubcategory(strSubCat)
		setCurrentResoures(currentCategory.subcategoriesByYear[strSubCat].resources)
	}

	return (
        <div className="flex flex-column">
			<Subheader 
				title="Resources" 
				category={objCategory.name} 
				subtitle = "Welcome to the neurelis medical affairs resource center"
				description="Your go-to source for the latest drug and disease state information from Neurelis." 
			/>

			<div className="flex page flex-column md:flex-row">
				<div className="desktopOnly">
					<ResourceMenu selectedCategory={selectedCategory} arrCategories={arrCategories} />
				</div>

	

				{ !objCategory.name && 
							<div>
								The resources found here are dedicated to providing comprehensive information on drug and disease state information from Neurelis. We invite you to explore these resources and discover how Neurelis is at the forefront of healthcare innovation. Together, we can drive the future of medicine and enhance the well-being of patients worldwide.
							</div>
				}

				{ !objCategory.name && 
					<div className="flex mobileOnly">
						<ResourceMenu selectedCategory={selectedCategory} arrCategories={arrCategories} />
					</div>
				}
				{ objCategory.name && 
				
					<div className="flex flex-column pb-5" style={{width: '100%'}}>
						<Link to="/resources" className="backLink mobileOnly">
							← BACK
						</Link>
						<div>
							<h1>{objCategory.name}</h1>
							{currentSubcategory && 
								<div className="flex flex-wrap flex-1 subcatMenuButton">
									{Object.keys(currentCategory.subcategoriesByYear).sort((a,b) => (a > b?1:-1)).map((cur, index) => {
										const subcatClassName = classNames('flex mr-3 mb-2 uppercase', {
											selected: cur === currentSubcategory
										})
										
										return (
											<div key={index} className={subcatClassName} onClick={(e) => fncSetSubcat(cur) }>
												{currentCategory.subcategoriesByYear[cur].name}
											</div>
										)
									})}
								</div>
							}

							{objCategory.name === 'Posters / Presentations' && currentCategory.subcategoriesByYear && currentCategory.subcategoriesByYear[currentSubcategory] && 
								<div className="flex flex-column">
									{Object.keys(currentCategory.subcategoriesByYear[currentSubcategory].years).sort((a,b) => (a > b?-1:1)).map((curYear, index) => {
										return (
											<div className="flex flex-column">
												{Object.keys(currentCategory.subcategoriesByYear[currentSubcategory].years).length > 1 && 
													<div className="flex yearTitleBar">
														{curYear}
													</div>
												}
												<div>
													{currentCategory.subcategoriesByYear[currentSubcategory].years[curYear].resources.map((cur,index) => {
														return <Resource key={index} resource={cur} index={index} resourceType={currentCategory.name} fncDisplayLoginPanel={fncDisplayLoginPanel} />
													})}
												</div>
											</div>
										)
									})}
								</div>
							}

							{objCategory.name !== 'MSL Map' && objCategory.name !== 'Podcasts' && objCategory.name !== 'Posters / Presentations' &&
								<div className="flex flex-column">
									{currentResoures?.map((cur,index) => {
										return <Resource key={index} resource={cur} index={index} resourceType={currentCategory.name} fncDisplayLoginPanel={fncDisplayLoginPanel} />
									})}
								</div>
							}
							{objCategory.name === 'Podcasts' && 
								<div className="flex flex-column">
									{podcasts.map((cur, index) => {
										return <Resource key={index} resource={cur} index={index} resourceType='podcast' fncDisplayLoginPanel={fncDisplayLoginPanel} />
									})}
								</div>
							}
							{objCategory.name === 'MSL Map' && 
								<div className="flex flex-column">
									<div className="flex justify-content-center">
										<img src={"/images/mlsmap.png"} alt="Neurelis Map" title="" className="max-w-full" />
									</div>
									
									<div className="flex flex-wrap mt-4">
										{msl.map((cur, index) => {
											return (
												<div key={index} className="flex  mslCard ">
													<img src={"/images/msl/" + cur.photo} />
													<div className="flex flex-column ml-4 mb-4">
														<div className="title">
															{cur.name}
														</div>
														<div>
															{cur.degree}
														</div>
														<div>
															{cur.title}
														</div>
														<div>
															{cur.region}
														</div>
														<div>
															<Link to={"mailto:" + cur.email}>{cur.email}</Link>
														</div>
													</div>
												</div>
											)
										})}
										
									</div>
								</div>
							}
							
							
						</div>
						<div className="resourceNotice">
							{RESOURCE_NOTICE}
						</div>
					</div>
				}
			</div>
			
		</div>
    );
}

export default Resources;