import React, { useState, useEffect }  from 'react';

// Needed 3rd party libraries
import { useParams  } from 'react-router-dom';

// Prime React Components

// Needed Components / Images / Constants
import Subheader from '../components/Subheader'
import FollowUpRequestForm from '../components/FollowUpRequestForm';

const FollowUp = (props) => {
	const { fncDisplayLoginPanel } = props;

	return (
        <div className="flex flex-column">
			<Subheader 
				title="Request a Follow-Up From a Medical Science Liaison" 
			/>

			<div className="flex page contactpage flex-column md:flex-row">
				<FollowUpRequestForm fncDisplayLoginPanel={fncDisplayLoginPanel} />
			</div>
		
		</div>
    );
}

export default FollowUp;