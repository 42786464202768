import React from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faThreads, faTwitter, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import NeurelisLogo  from '../images/neurelis_logo.png'

const Footer = () => {   
	return (
		<div className="footer">
			<div className="grid">
				<div className="col ftlogo">
					<Link to="/" className="logo" >
						<img src={NeurelisLogo} alt="Neurelis Logo" title=""  />
					</Link>
				</div>
			</div>
			<div className="footersocial">
		<p>Follow Us:</p>
	  <a href="https://www.linkedin.com/company/neurelis-medical-affairs/" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faLinkedin} className="fa-lg" />
      </a>
      <a href="https://www.threads.net/@neurelismedical" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faThreads} className="fa-lg" />
      </a>
	  <a href="https://twitter.com/neurelismedical" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faXTwitter} className="fa-lg" />
      </a>
    </div>
			<div className="grid">
				<div className="col-12 lg:col-3  sm:col-12 fttxt">
					<p className="pl-2">&copy; Neurelis, Inc. 2022.</p>
					<p className="pl-2">All Rights Reserved.</p>
				</div>
			
				<div className="col-12 lg:col-3  sm:col-12 ftlink">
					<ul>
						<li>
							<a href="https://www.neurelis.com" target="_blank">neurelis.com</a>
						</li>

						<li>
							<a href="https://www.neurelisclinicaltrials.com" target="_blank" to="/">neurelisclinicaltrials.com</a>
						</li>
					</ul>
				</div>

				<div className="col-12 lg:col-3  sm:col-12 ftlink">
					<ul>

						<li>
							<Link to="/about">About</Link>
						</li>

						<li>
							<Link to="/mirf">MIRF</Link>  
						</li>

						<li>
							<Link to="/follow-up">Request F/U</Link>
						</li>
					</ul>
				</div>

				<div className="col-12 lg:col-3  sm:col-12 ftlink">
					<ul>
						<li>
							<a href="https://www.neurelis.com/privacy-policy" target="_blank">Privacy Policy</a>
						</li>

						<li>
							<a href="https://www.neurelis.com/terms-of-use" target="_blank">Terms of Service</a>
						</li>
					</ul>
				</div>	
			</div>
		</div>
    );
}

export default Footer;