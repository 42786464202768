import React, { useState, useRef } from "react";
import { useSearchParams } from 'react-router-dom';

import { useFormik } from 'formik';
import axios from 'axios';
import { classNames } from 'primereact/utils';
import { InputText } from "primereact/inputtext";
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from "primereact/inputtextarea";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Checkbox } from "primereact/checkbox";

import ReCaptcha from '../components/ReCaptcha'

import { FOLLOW_UP } from '../constants/URLS';
import { useAuth } from "../hooks/useAuth";
import { STATE_DROPDOWN, CREDENTIALS_DROPDOWN} from "../constants/registerUserData"

const FollowUpRequestForm = (props) => { 
	const toast = useRef(null);
	const { fncDisplayLoginPanel } = props;
	const user = JSON.parse(localStorage.getItem("user"));

	const { loggedIn } = useAuth();
	const isLoggedIn = loggedIn()
	const [queryParameters] = useSearchParams()
	const [ captchaValidated, setCaptchaValidated ] = useState(false);
	const [ displayCaptchaError, setDisplayCaptchaError ] = useState(false);

	const [ submitted, setSubmitted ] = useState(false);
	const [ pageView, setPageView ] = useState('follow-up')
	const [ufirstname, setFirstName] = useState(isLoggedIn?user.userDetails.first_name:'');
	const [ulastname, setLastName] = useState(isLoggedIn?user.userDetails.last_name:'');

	const [ucity, setCity] = useState('');
	const [uzipcode, setCode] = useState(null);
	const [uemail, setEmail] = useState(isLoggedIn?user.userDetails.email:'');
	const [uphone, setPhone] = useState(isLoggedIn?user.userDetails.phone:'');
	const [ustate, setState] = useState(isLoggedIn?user.userDetails.state:'');
	const [umessage, setMessage] = useState('');
	const [hasErrors, setHasErrors] = useState(false);

	const [checked, setChecked] = useState(false);

	const methodcontact = [
        { name: 'Email', key: 'Email' },
        { name: 'Phone', key: 'Phone' }
    ];
    const [selectedMethod, setSelectedMethod] = useState([])

    const localmedical = [
        { name: 'Yes', key: 'Yes' },
        { name: 'No', key: 'No' }
    ];
    const [selectedMedical, setSelectedMedical] = useState(localmedical)
	
	const fncCaptchaValidated = () => {
		setCaptchaValidated(true)
		setDisplayCaptchaError(false)
	}
	const validationError = useRef(null);

	const fncRegisterValidate = async (event) => {
		setSubmitted(true);
		let errors = []
		if (!ufirstname.trim()) errors.push('first_name')
		if (!ulastname.trim()) errors.push('last_name')
		if (!uemail.trim()) errors.push('email')

		// We have errors, display correct message and leave
		if (errors.length > 0) {
			console.log(errors)
			setHasErrors(true)
			return
		}

		if (!captchaValidated) {
			setDisplayCaptchaError(true)
			return
		}

		setHasErrors(false)
		let data = {
          'first_name': ufirstname,
          'last_name': ulastname,
		  'confirm': checked,
          'city': ucity,
          'zip_code': uzipcode,
          'email': uemail,
          'phone': uphone,
          'state': ustate,
          'method': selectedMethod,
          'medical': selectedMedical,
          'message': umessage,
        }
      	
		let followResults = await axios({
			method: 'post', 
			url: FOLLOW_UP,
			data: data,
		})

		if (followResults.data.success) {

			React.event("Follow up Form Submission");

			setPageView('confirmation')
			
		} else {
			toast.current.show({ severity: 'error', summary: 'Error', detail: 'There was an issue, please try again later.', life: 3000 });
		}
		
	}




	return (
		 <div className="flex flex-column my-account" style={{ width: '100%'}}>
			<Toast ref={toast} />
			{pageView === 'follow-up' && 

				<div className="inputForm">
					{ !isLoggedIn &&
						<div className="border-1 border-primary py-4 px-4 flex">
							<div className="w-27rem m-auto">
								<h3 className="mb-3 text-primary">Registration Required</h3>
								<p className="font-semibold">Registration is required to submit a Medical Information Request Form. Please log in to your account or complete the registration form to create a new account.</p>
								<div className="flex registration-required-header headerButtons">
									<div className="mr-2 flex-auto">
										<Button className="w-full text-center" text severity="secondary" onClick={(e) => fncDisplayLoginPanel('login')} >
											Login
										</Button>
									</div>
									<div className="flex-auto">
										<Button className="w-full text-center" severity="secondary" label="Register" outlined onClick={(e) => fncDisplayLoginPanel('register')} />
									</div>	
								</div>
							</div>
						</div>
					}
					<form className={` ${ !isLoggedIn ? "locked" : "" } submit-question-form follow-up-form`}>
						{ !isLoggedIn ? 
							<div className="overlay"></div>
						: ''
						}
						<div>
							<Toast ref={toast} />
					
							<h1 className="mb-4 pl-2">Submit a Request</h1>
							
							<div className="grid">

								<div className="col-12 md:col-6 xl:col-6 mb-2">
									<div className="field fullField"  >
										<span className="p-float-label">
											<InputText 
												id="first_name" 
												value={ufirstname} 
												onChange={(e) => setFirstName(e.target.value)}
												required 
												className={classNames('p-inputtext', { 'p-invalid': submitted && !ufirstname })} 
											/>
											<label htmlFor="first_name">First Name*</label>
										</span>
										{submitted && !ufirstname && <small className="p-error">First Name is required.</small>}
									</div>
								</div>

								<div className="col-12 md:col-6 xl:col-6 mb-2">
									<div className="field fullField"  >
										<span className="p-float-label">
											<InputText 
												id="last_name" 
												value={ulastname} 
												onChange={(e) => setLastName(e.target.value)}
												required 
												className={classNames('p-inputtext', { 'p-invalid': submitted && !ulastname })} 
											/>
											<label htmlFor="first_name">Last Name*</label>
										</span>
										{submitted && !ulastname && <small className="p-error">Last Name is required.</small>}
									</div>
								</div>

								<div className="col-12 px-3 mb-2">
									<div className="col-12 mb-2">
									    <div className="flex align-items-center">
							                <Checkbox 
							                	inputId="checked"
							                	onChange={e => setChecked(e.checked)} 
							                	checked={checked}>
							         		</Checkbox>
							                <label htmlFor="checked" className="ml-2">I confirm that I am a healthcare professional practicing in the United States.</label>
							            </div>
									</div>
								</div>

								<div className="col-12 md:col-4 xl:col-4 mb-2">
									<div className="field fullField">
										<span className="p-float-label">
											<InputText 
												id="city" 
												value={ucity} 
												onChange={(e) => setCity(e.target.value)}
											/>
											<label htmlFor="email">City</label>
										</span>
									</div>
								</div>

								<div className="col-12 md:col-4 xl:col-4 mb-2">
									<div className="field fullField"  >
										<span className="p-float-label">
											<Dropdown 
												value={ustate}
												onChange={(e) => setState((prev) => (prev = e.value))} 
												options={STATE_DROPDOWN} 
											/>
											<label htmlFor="email">State</label>
										</span>
									</div>
								</div>

								<div className="col-12 md:col-4 xl:col-4 mb-2">
									<div className="field fullField">
										<span className="p-float-label">
											<InputNumber 
												useGrouping={false}
												id="zip_code" 
												value={uzipcode} 
												required
												onChange={(e) => setCode(e.value)}
												className={classNames({ 'p-invalid': submitted && !uzipcode })} 
											/>
											<label htmlFor="zip_code">ZIP Code*</label>
										</span>
										{submitted && !uzipcode && <small className="p-error">Zip Code is required.</small>}
									</div>
								</div>

								<div className="col-12 md:col-6 xl:col-6 mb-2">
									<div className="field fullField"  >
										<span className="p-float-label">
											<InputText 
												id="email" 
												value={uemail} 
												onChange={(e) => setEmail(e.target.value)}
												required 
												autoComplete="email"
												className={classNames('p-inputtext', { 'p-invalid': submitted && !uemail })} 
											/>
											<label htmlFor="email">Email*</label>
										</span>
										{submitted && !uemail && <small className="p-error">Email is required.</small>}
									</div>
								</div>

								<div className="col-12 md:col-6 xl:col-6 mb-2">
									<div className="field fullField"  >
										<span className="p-float-label">
											<InputMask 
												id="phone"
												value={uphone} 
												onChange={(e) => setPhone(e.target.value)} 
												mask="(999) 999-9999" 
												autoComplete="phone"
											/>
											<label htmlFor="phone">Phone Number</label>
										</span>
									</div>
								</div>

								<div className="col-12 px-3">
									<div className="col-12 mb-3">
										<h5 className="font-bold mb-2 text-primary">Preferred method of contact:</h5>
									    <div className="flex flex-row gap-3">
							                {methodcontact.map((method) => {
							                    return (
							                        <div key={method.key} className="flex align-items-center">
							                            <RadioButton inputId={method.key} name="method" value={method} onChange={(e) => setSelectedMethod(e.value)} checked={selectedMethod.key === method.key} />
							                            <label htmlFor={method.key} className="ml-2">{method.name}</label>
							                        </div>
							                    );
							                })}
							            </div>
									</div>
								</div>

								<div className="col-12 px-3">
									<div className="col-12 mb-0">
										<h5 className="font-bold mb-2 text-primary">Would you like to be contacted by your local Medical Science Liaison?</h5>
									    <div className="flex flex-row gap-3">
							                {localmedical.map((local) => {
							                    return (
							                        <div key={local.key} className="flex align-items-center">
							                            <RadioButton inputId={local.key} name="local" value={local} onChange={(e) => setSelectedMedical(e.value)} checked={selectedMedical.key === local.key} />
							                            <label htmlFor={local.key} className="ml-2">{local.name}</label>
							                        </div>
							                    );
							                })}
							            </div>
									</div>
								</div>

								<div className="col-12 mb-2">
									<div className="field fullField">
										<span className="p-float-label">
											<InputTextarea 
												id="description"
												value={umessage} 
												onChange={(e) => setMessage(e.target.value)} 
												rows={5} cols={30} 
												className={classNames({ 'p-invalid': submitted && !umessage })}											/>
											<label htmlFor="description">Your Questions/Comments*</label>
										</span>	

										{submitted && !umessage && <small className="p-error">Questions/Comments is required.</small>}
										
									</div>
								</div>

								<div className="col-12 px-4 mb-2">
									<p>Neurelis respects your privacy. Please see our privacy policy for more information.</p>
								</div>
					
							</div>
							{hasErrors && 
								<div className="flex  justify-content-center red mb-2 boldText" >
									Please fill in all required fields.
								</div>
							}


							<div className="col-12 md:col-12 xl:col-12 mb-0">
								<ReCaptcha 
									fncCaptchaValidated={fncCaptchaValidated}
									displayCaptchaError={displayCaptchaError}
								/>
							</div>

							<div className="flex justify-content-center">
								<div className="submitButton flex align-items-center justify-content-center" onClick={fncRegisterValidate}>
									Submit
								</div>
							</div>
						</div>
					</form>
				</div>
			}

			{pageView === 'confirmation' && 
				<div className="flex confirmation justify-content-center pb-4" >
					Thank you for submitting!
				</div>
			}
		</div>
    );
}

export default FollowUpRequestForm;