import React, { useState, useEffect }  from 'react';

// Needed 3rd party libraries
import { useParams  } from 'react-router-dom';

// Prime React Components

// Needed Components / Images / Constants
import Subheader from '../components/Subheader'
import RequestForm from '../components/RequestForm';

const MedicalRequest = (props) => {
	const { fncDisplayLoginPanel } = props;

	return (
        <div className="flex flex-column">
			<Subheader 
				title="Medical Information Request Form" 
			/>

			<div className="flex page contactpage flex-column md:flex-row">
				<RequestForm fncDisplayLoginPanel={fncDisplayLoginPanel} />
			</div>
		
		</div>
    );
}

export default MedicalRequest;