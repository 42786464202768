import React from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

const GrantsMenu = (props) => {   
	const { selectedGrant, arrCategories } = props
	const navigate = useNavigate();
	
	const fncNavigate = (curRow) => {
		if (curRow.internal) {
			navigate('/grants' +  curRow.link)
		} else {
			window.open(curRow.link,'_blank')
		}
		
	}
	
	return (
		<div className="flex flex-column subMenu">

			{arrCategories.map(cur => {
				
				const rowClassName = classNames('flex justify-content-between subMenuRow text-white', {
					selected: '/' + selectedGrant === cur.link
				})
				
				return (<>
					{(cur.internal == true  &&
						<div className={rowClassName} onClick={(e) => fncNavigate(cur) }>
							<div className="flex align-items-center" >
								<div className="name">{cur.name}</div>
							</div>
							<div className="flex align-items-center openIcon">
								{(cur.internal ? 
									<i className="pi pi-chevron-right" />
								:
									<i className="pi pi-external-link" />
								)}
							</div>
						</div>
					)}</>
				)
			})}
			
			<div className="external-menu">
				<div className="external-tooltip">
					<strong>If you have already submitted a grant application</strong	> and would like to check the status of your application, please use the applicable portal link below:
				</div>
				{arrCategories.map(cur => {
					
					const rowClassNameExternal = classNames('flex justify-content-between subMenuRow', {
						selected: '/' + selectedGrant === cur.link
					})
					
					return (<>
						{(cur.internal == false  &&
							<div className={rowClassNameExternal} onClick={(e) => fncNavigate(cur) }>
								<div className="flex align-items-center" >
									<div className="name">{cur.name}</div>
								</div>
								<div className="flex align-items-center openIcon">
									<i className="pi pi-arrow-up-right" />
								</div>
							</div>
						)}</>
					)
				})}
			</div>
		</div>
		
    );
}

export default GrantsMenu;