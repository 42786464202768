import React from "react";
import { useAuth } from "../hooks/useAuth";

const SearchResultsItem = (item) => {
	let objCur = item.allDetails

	const { loggedIn } = useAuth();
	const isLoggedIn = loggedIn() 
	return (
		<div className="flex align-items-top justify-content-between">
			<div className="flex">
				<div style={{width:'30px'}}>
					{objCur.locked === 1 && !isLoggedIn &&
						<i className="pi pi-lock mr-2"/>
					}
					{objCur.locked === 2 && !isLoggedIn &&
						<i className="pi pi-lock red mr-2"/>
					}
					{objCur.locked === 1 && isLoggedIn &&
						<i className="pi pi-unlock mr-2" />
					}
					{objCur.locked === 2 && isLoggedIn &&
						<i className="pi pi-unlock red mr-2" />
					}
				</div>
				{objCur.title}
			</div>
			<div>
				{objCur.document_type === 'pdf' && 
					<i className="red pl-2 pi pi-file-pdf" />
				}
			</div>
		</div>
	);
};

export default SearchResultsItem;