import React, { useState, useRef } from "react";
import classNames from 'classnames';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { useNavigate    } from 'react-router-dom';

// Needed Components / Images / Constants
import { useAuth } from "../hooks/useAuth";
import { fncGetPDF } from "../services/PDFService"
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

const Resource = (props) => {   
	const { resource, index, resourceType, fncDisplayLoginPanel } = props
	const navigate = useNavigate();
	const { loggedIn } = useAuth();
	const isLoggedIn = loggedIn() 
	const player = useRef();
  	const [playing, setPlaying] = useState(false)
	const [ displayLoggedInMessage, setDisplayLoggedInMessage ] = useState(false);
  	const play = () => setPlaying(true)
  	const pause = () => setPlaying(false)
  	const [visible, setVisible] = useState(false);
	const rowClassName = classNames('flex resource align-items-center justify-content-between', {
        'oddRow': (index % 2)
    });

	const fncLoadResource = async (resource) => {
		if (resource.locked === 2) {
			navigate('/mirf?s=' +  resource.resourceID)
		} else {
			if (resource.url && resource.url.indexOf('http') === 0) {
				window.open(
					resource.url,
					'_blank'
				);
			} else if (resource.locked === 0 || isLoggedIn) {
				let results = await fncGetPDF('resource-' + resource.resourceID)
				if (results === 'no-token') {
					setDisplayLoggedInMessage(true)
				}
			} else {
				setDisplayLoggedInMessage(true)
			}
		}
	}

	const audiofunction = () => {
	    player.current.audio.current.play();
	};
	const audiofunctionpause = () => {
	    player.current.audio.current.pause();
	};

	return (
		<div className={rowClassName} >
			
			{resourceType !== 'podcast' && resourceType !== 'infographics' && resourceType !== 'cme' &&
				<>
				<div className="flex link" onClick={(e) => fncLoadResource(resource)}>
					{resource.locked === 1 && !isLoggedIn &&
						<i className="pi pi-lock mr-2"/>
					}
					{resource.locked === 2 && !isLoggedIn &&
						<i className="pi pi-lock red mr-2"/>
					}
					{resource.locked === 1 && isLoggedIn &&
						<i className="pi pi-unlock mr-2" />
					}
					{resource.locked === 2 && isLoggedIn &&
						<i className="pi pi-unlock red mr-2" />
					}
					<div className="flex flex-column">
						<div className="shortTitle">
							{resource.short_title}
						</div>
						<div>
							{resource.source}
						</div>
					</div>
					
				</div>
				{(resource.locked === 0 || isLoggedIn) && 
					<div>
						<i className="pi pi-arrow-up-right link" />
					</div>
				}
			</>
			}
			{resourceType === 'cme' &&
				<>
				<div className="flex link" onClick={(e) => fncLoadResource(resource)}>
					{resource.locked === 1 && !isLoggedIn &&
						<i className="pi pi-lock mr-2"/>
					}
					{resource.locked === 2 && !isLoggedIn &&
						<i className="pi pi-lock red mr-2"/>
					}
					{resource.locked === 1 && isLoggedIn &&
						<i className="pi pi-unlock mr-2" />
					}
					{resource.locked === 2 && isLoggedIn &&
						<i className="pi pi-unlock red mr-2" />
					}
					<div className="flex flex-row align-items-center">
						{resource.image &&
							<div className="pr-2">
								<img className="w-full" src={"/images/resources/" + resource.image} style={{ maxWidth: '100px' }} />
							</div>
						}
						<div>
							<div className="shortTitle">
								{resource.short_title}
							</div>
							<div>
								{resource.source}
							</div>
						</div>
					</div>
					
				</div>
				{(resource.locked === 0 || isLoggedIn) && 
					<div>
						<i className="pi pi-arrow-up-right link" />
					</div>
				}
			</>
			}
			{resourceType === 'infographics' && 
				<>
					<div className="flex">
						{resource.locked === 1 && !isLoggedIn &&
							<i className="pi pi-lock mr-2"/>
						}
						{resource.locked === 2 && !isLoggedIn &&
							<i className="pi pi-lock red mr-2"/>
						}
						{resource.locked === 1 && isLoggedIn &&
							<i className="pi pi-unlock mr-2" />
						}
						{resource.locked === 2 && isLoggedIn &&
							<i className="pi pi-unlock red mr-2" />
						}
						<div className="flex flex-column">
							<div className="shortTitle link" onClick={() => setVisible(true)}>
								{resource.short_title}
							</div>
							<div>
								{resource.source}
							</div>
						</div>
					</div>
					
					{(resource.locked === 0 || isLoggedIn) && 
						<div onClick={() => setVisible(true)}>
							<i className="pi pi-arrow-up-right link" />
						</div>
					}
					{ resource.url != false &&
						<Dialog className="infographics-model" visible={visible} onHide={() => setVisible(false)}
			                style={{ width: '1360px', height: '770px' }} breakpoints={{ '960px': '90%', '641px': '90%' }}>
			                <iframe src={"/infographics/" + resource.url}></iframe>
			            </Dialog>
			        }
		
				</>
			}
			{resourceType === 'podcast' && 
				<div className="flex flex-column col-12 p-0">
					<div className="flex align-items-center mb-3">
						{playing ? 
							<div className="mp3Icon pauseIcon flex align-items-center justify-content-center" onClick={audiofunctionpause}>
								<i className="pi pi-pause" />
							</div>
						 : 
							<div className="mp3Icon playIcon flex align-items-center justify-content-center" onClick={audiofunction}>
								<i className="pi pi-play" />
							</div>
						}
						<div className="ml-3 shortTitle">
							{resource.title}
						</div>
					</div>


					<AudioPlayer
					    src={"/podcasts/" + resource.path}
					    id='player'
					    onPlay={play}
					    onPause={pause}
					    customAdditionalControls={[]} 
					    showDownloadProgress={false} 
					    layout="horizontal"
					    ref={player}
					    // className={playing ? 'show' : 'hidden'}
					/>
					
					<div className="flex flex-wrap gap-2 mb-3">
						<div className="mp3Button flex align-items-center justify-content-center">
							<a className="flex align-items-center" href={"/podcasts/" + resource.path} rel="noopener noreferrer" download target="_blank">
								<div className="neurelisIcons">
									<div className="icon download" />
								</div>
								Download
							</a>
						</div>

						{resource.actionPlanURL !== "" ?
							<div className="mp3Button flex align-items-center justify-content-center">
								<a className="flex align-items-center" href={actionPlanURL} target="_blank" rel="noopener noreferrer">
									<div className="neurelisIcons">
										<div className="icon actionplan" />
									</div>
									Action Plan
								</a>
							</div>
							: resource.actionPlan !== "" ?
							<div className="mp3Button flex align-items-center justify-content-center">
								<a className="flex align-items-center" href={"/pdfs/" + resource.actionPlan} target="_blank" rel="noopener noreferrer">
									<div className="neurelisIcons">
										<div className="icon actionplan" />
									</div>
									Action Plan
								</a>
							</div>
							: ""
						}
						
						{resource.manuscriptURL !== "" ? 
							<div className="mp3Button flex align-items-center justify-content-center">
								<a className="flex align-items-center" href={resource.manuscriptURL} target="_blank" rel="noopener noreferrer">
									<div className="neurelisIcons">
										<div className="icon manuscript" />
									</div>
									manuscript
								</a>
							</div>
							: resource.manuscript !== "" ?
							<div className="mp3Button flex align-items-center justify-content-center">
								<a className="flex align-items-center" href={"/pdfs/" + resource.manuscript} target="_blank" rel="noopener noreferrer">
									<div className="neurelisIcons">
										<div className="icon manuscript" />
									</div>
									manuscript
								</a>
							</div>
							: ""
						}

						{resource.transcriptURL !== "" ?
							<div className="mp3Button flex align-items-center justify-content-center">
								<a className="flex align-items-center" href={resource.transcriptURL} target="_blank" rel="noopener noreferrer">
									<div className="neurelisIcons">
										<div className="icon transcript" />
									</div>
									Transcript
								</a>
								
							</div>
							: resource.transcript !== "" ?
							<div className="mp3Button flex align-items-center justify-content-center">
								<a className="flex align-items-center" href={"/pdfs/" + resource.transcript} target="_blank" rel="noopener noreferrer">
									<div className="neurelisIcons">
										<div className="icon transcript" />
									</div>
									Transcript
								</a>
								
							</div>
							: ""
						}
					</div>
				</div>

			}
			 <Dialog header="Login or Register" visible={displayLoggedInMessage} style={{ width: '95%', maxWidth: '600px' }} onHide={() => setDisplayLoggedInMessage(false)}>
                <div className="flex flex-column m-0">
					<div className="pb-4">
						Access to this resource is restricted.
					</div>
					<div className="pb-2">
						Please login or register an account to view this resource.
					</div>
					<div className="flex justify-content-center">
						<div className="mr-2 ">
							<Button text severity="secondary" onClick={(e) => fncDisplayLoginPanel('login')}>
								<i className="pi pi-sign-in flipIcon mr-1" />
								Login
							</Button>
						</div>
						<div>
							<Button severity="secondary" icon="pi pi-user" label="Register"  outlined onClick={(e) => fncDisplayLoginPanel('register')} />
						</div>
					</div>
                    
                </div>
            </Dialog>
		</div>
		
    );
}

export default Resource;