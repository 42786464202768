import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';

// Prime React Components
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';

import ReCaptcha from '../components/ReCaptcha'

import ReactGA from "react-ga4";

import { STATE_DROPDOWN, CREDENTIALS_DROPDOWN} from "../constants/registerUserData"

const Register = (props) => {   
	const { fncCloseDisplayLoginPanel, registerData, fncSetRegisterData, fncRegister, fncClearRegisterData, fncDisplayLoginPanel } = props
	const toast = useRef(null);
	
	const [ submitted, setSubmitted ] = useState(false);
	const [registerError, setRegisterError] = useState(false);
	const [displayBadPasswordMatch, setDisplayBadPasswordMatch] = useState(false);
	const [displayBadLogin, setDisplayBadLogin] = useState(false);

	// NEEDED FOR ReCaptcha
	const [ captchaValidated, setCaptchaValidated ] = useState(false);
	const [ displayCaptchaError, setDisplayCaptchaError ] = useState(false);
	
	const fncCaptchaValidated = () => {
		setCaptchaValidated(true)
		setDisplayCaptchaError(false)
	}
	// END OF ReCaptcha


	const fncDisplayLogin = () => {
		fncDisplayLoginPanel('login')
	}
	
	const handleSubmit = async () => {
		setDisplayBadLogin(false)
		setDisplayBadPasswordMatch(false)
		setSubmitted(true);
		let errors = []
		if (!registerData.credentials.trim()) errors.push('credentials')
		if (!registerData.first_name.trim()) errors.push('first_name')
		if (!registerData.last_name.trim()) errors.push('last_name')
		if (!registerData.institution.trim()) errors.push('institution')
		if (!registerData.email.trim()) errors.push('email')
		if (!registerData.password.trim() || registerData.password.trim().length < 6) errors.push('password')
		if (!registerData.state.trim()) errors.push('state')
		
		// We have errors, display correct message and leave
		if (errors.length > 0) {
			setDisplayBadLogin(true)
			return
		}
		if (registerData.password.trim() !== registerData.confirm_password.trim()) {
			errors.push('emailConfirm')
			setDisplayBadPasswordMatch(true)

			return		
		}

		if (!captchaValidated) {
			setDisplayCaptchaError(true)
			return
		}
		
		let results = await fncRegister();

		if (!results) {
			setRegisterError(true)
			return 
		}
		
		ReactGA.event("Registration Form Submission");



		setSubmitted(false);
		fncClearRegisterData()
		fncCloseDisplayLoginPanel()
	}
	
	return (
		<div className="flex flex-column inputForm">
			
				<Toast ref={toast} />
				<h2 className="flex justify-content-between align-items-center">
					Register 
					<Link className="text-base underline text-primary" onClick={() => fncDisplayLogin()}>Login</Link>
				</h2>
				{displayBadLogin && 
					<div className="flex  justify-content-center red mb-2 boldText" >
						Please fill in all fields.
					</div>
				}

				{displayBadPasswordMatch && 
					<div className="flex  justify-content-center red mb-2 boldText" >
						Please Make sure the password fields match.
					</div>
				}

				{registerError && 
					<div className="flex  justify-content-center red mb-2 boldText" >
						There was an error registering, please try again later.
					</div>
				}
				
				<div className="inputFormFields">
					<div className="flex">
						<div className="field fullField"  >
							<span className="p-float-label">
								<Dropdown 
									value={registerData.credentials} 
									onChange={(e) => fncSetRegisterData('credentials',e.value)} 
									options={CREDENTIALS_DROPDOWN} 
									className={classNames({ 'p-invalid': submitted && !registerData.credentials })} 
								/>
								<label htmlFor="credentials">Credentials</label>
							</span>
							{submitted && !registerData.credentials && <small className="p-error">Credentials are required.</small>}
						</div>
					</div>

					<div className="flex">
						<div className="field fullField"  >
							<span className="p-float-label">
								<InputText 
									id="first_name" 
									value={registerData.first_name} 
									onChange={(e) => fncSetRegisterData('first_name',e.target.value)} 
									required 
									className={classNames('p-inputtext', { 'p-invalid': submitted && !registerData.first_name })} 
								/>
								<label htmlFor="first_name">First Name</label>
							</span>
							{submitted && !registerData.first_name && <small className="p-error">First Name is required.</small>}
						</div>
					</div>

					<div className="flex">
						<div className="field fullField"  >
							<span className="p-float-label">
								<InputText 
									id="last_name" 
									value={registerData.last_name} 
									onChange={(e) => fncSetRegisterData('last_name',e.target.value)} 
									required 
									className={classNames('p-inputtext', { 'p-invalid': submitted && !registerData.last_name })} 
								/>
								<label htmlFor="last_name">Last Name</label>
							</span>
							{submitted && !registerData.last_name && <small className="p-error">Last Name is required.</small>}
						</div>
					</div>

					<div className="flex">
						<div className="field fullField"  >
							<span className="p-float-label">
								<InputText 
									id="institution" 
									value={registerData.institution} 
									onChange={(e) => fncSetRegisterData('institution',e.target.value)} 
									required 
									className={classNames('p-inputtext', { 'p-invalid': submitted && !registerData.institution })} 
								/>
								<label htmlFor="institution">Institution</label>
							</span>
							{submitted && !registerData.institution && <small className="p-error">Institution is required.</small>}
						</div>
					</div>

					<div className="flex">
						<div className="field fullField"  >

							<span className="p-float-label">
								<InputText 
									id="email" 
									value={registerData.email} 
									onChange={(e) => fncSetRegisterData('email',e.target.value)} 
									required 
									autoComplete="email"
									className={classNames('p-inputtext', { 'p-invalid': submitted && !registerData.email })} 
								/>
								<label htmlFor="email">Email</label>
							</span>
							{submitted && !registerData.email && <small className="p-error">Email is required.</small>}
						</div>
					</div>

					<div className="flex">
						<div className="field fullField"  >
							<span className="p-float-label">
								<InputText 
									id="password" 
									type="password"
									value={registerData.password} 
									onChange={(e) => fncSetRegisterData('password',e.target.value)} 
									required 
									className={classNames('p-inputtext', { 'p-invalid': submitted && !registerData.password })} 
								/>
								<label htmlFor="password">Password</label>
							</span>
							{submitted && (!registerData.password || registerData.password.trim().length < 6) && <small className="p-error">Password is required.</small>}
						</div>
					</div>

					<div className="flex">
						<div className="field fullField"  >
							<span className="p-float-label">
								<InputText 
									id="confirm_password" 
									type="password"
									value={registerData.confirm_password} 
									onChange={(e) => fncSetRegisterData('confirm_password',e.target.value)} 
									required 
									className={classNames('p-inputtext', { 'p-invalid': submitted && !registerData.confirm_password })} 
								/>
								<label htmlFor="confirm_password">Confirm Password</label>
							</span>
							{submitted && !registerData.confirm_password && <small className="p-error">Confirm Password is required.</small>}
						</div>
					</div>
					<div className="flex">
						<div className="field fullField"  >
							<span className="p-float-label">
								<Dropdown 
									value={registerData.state} 
									onChange={(e) => fncSetRegisterData('state',e.value)} 
									options={STATE_DROPDOWN} 
									className={classNames({ 'p-invalid': submitted && !registerData.state })} 
								/>
								<label htmlFor="state">State</label>
							</span>
							{submitted && !registerData.state && <small className="p-error">State is required.</small>}
						</div>
					</div>
					<div className="flex">
						<div className="flex flex-column field fullField"  >
							<div className="flex">
								
								<Checkbox 
									className="mr-2"
									onChange={(e) => fncSetRegisterData('opt_in',e.checked)} 
									checked={registerData.opt_in} 
								/>
								<label htmlFor="confirm_password">Opt-in</label>
							</div>
							<div className="mt-2">
								To receive email updates, check this Opt-in box
							</div>
						
						</div>
					</div>
				</div>

				<ReCaptcha 
					fncCaptchaValidated={fncCaptchaValidated}
					displayCaptchaError={displayCaptchaError}
				/>

				<div className="flex  justify-content-center">
					<button className="submitButton flex align-items-center justify-content-center border-none" onClick={handleSubmit}>
						Register
					</button>
				</div>
				

				
			
			
		</div>
    );
}

export default Register;