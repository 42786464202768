import React from "react";
import { InputText } from "primereact/inputtext";
import { Button } from 'primereact/button';

import { useFormik } from 'formik';

const MedicalGrantForm = (props) => { 

    const { handleBlur, handleSubmit, errors, touched, getFieldProps, handleChange, setFieldValue } = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
        },
        validate: (values) => {
            let errors = {};

            if (!values.first_name) {
                errors.first_name = 'Required';
            } 
            if (!values.last_name) {
                errors.last_name = 'Required';
            } 
            return errors;
        },
        onSubmit: values => {
            setTimeout(() => {
               console.log(JSON.stringify(values, null, 2));
             }, 400);
        },
    });
        
    return (
        <div className="flex flex-column" style={{ width: '100%'}}>

            <form onSubmit={handleSubmit} className="medical-grant-form">

                <div className="flex flex-column">

                    <div className="input-row">
                        <label htmlFor="first_name">First Name</label>
                        <InputText
                            id="first_name"
                            label="First Name"
                            {...getFieldProps('first_name')}
                            className="w-12"
                            />
                            {(touched.first_name && errors.first_name) && <span className="error red">{errors.first_name}</span>}
                    </div>

                    <div className="input-row">
                        <label htmlFor="last_name">Last Name</label>
                        <InputText
                            id="last_name"
                            label="Last Name"
                            {...getFieldProps('last_name')}
                            className="w-12"
                            />
                            {(touched.last_name && errors.last_name) && <span className="error red">{errors.last_name}</span>}
                    </div>

    
                    <div className="submit-form-btn">
                        <Button type="submit" />
                    </div>
               
                </div>

            </form>
        
        </div>      
    );
}

export default MedicalGrantForm;