import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';

// Prime React Components
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';

import ReCaptcha from '../components/ReCaptcha'

import ReactGA from "react-ga4";

const Login = (props) => {   
	const { fncCloseDisplayLoginPanel, fncLogin, resetPassword, fncSendToastMessage } = props

	const toast = useRef(null);
	const [	email, setEmail] = useState(''); // Email Address for logging in
	const [	password, setPassword] = useState(''); // Password for logging in
	const [ submitted, setSubmitted ] = useState(false);
	const [ displayReset, setDisplayReset] = useState(false);
	const [ displayBadLogin, setDisplayBadLogin] = useState(false);
	const [ panelView, setPanelView ] = useState('login')

	// NEEDED FOR ReCaptcha
	const [ captchaValidated, setCaptchaValidated ] = useState(false);
	const [ displayCaptchaError, setDisplayCaptchaError ] = useState(false);
	
	const fncCaptchaValidated = () => {
		setCaptchaValidated(true)
		setDisplayCaptchaError(false)
	}
	// END OF ReCaptcha

	const handleSubmit = async (event) => {
		event.preventDefault();
		setSubmitted(true);
		let errors = []
		if (!email.trim()) errors.push('email')
		if (!password.trim()) errors.push('password')
		
		// We have errors, just leave function
		if (errors.length > 0) return

		let results = await fncLogin({
			username: email,
		 	password: password
		});

		if (results === 'reset') {
			setDisplayReset(true)
			return
		} else if (!results) {

			ReactGA.event("Login Failed");

			setDisplayBadLogin(true)
			return
		}

		ReactGA.event("login");

		setSubmitted(false);
		fncCloseDisplayLoginPanel()
	}

	const handleReset = async (event) => {
		event.preventDefault();
		setSubmitted(true);
		let errors = []
		if (!email.trim()) errors.push('email')
		
		// We have errors, just leave function
		if (errors.length > 0) return
		if (!captchaValidated) {
			setDisplayCaptchaError(true)
			return
		}

		let results = await resetPassword({
			u: email
		});

		if (results) {

			ReactGA.event("Password Reset");



			fncSendToastMessage('success','Forgot password email has been sent.')
		} else {
			fncSendToastMessage('error','There was a problem sending email.')
		}
		

		setSubmitted(false);
		fncCloseDisplayLoginPanel()
	}

	
	return (
		<div className="flex flex-column inputForm">
			
			{
		        panelView === 'login' ?
					<form onSubmit={handleSubmit}>
						
						<h2 className="flex justify-content-between align-items-center">
							Login 
							<Link className="text-base underline text-primary" onClick={() => setPanelView('reset')}>Reset Password</Link>
						</h2>
						<div className="flex oddRow">
							<div className="field fullField"  >

								<span className="p-float-label">
									<InputText 
										id="email" 
										value={email} 
										onChange={(e) => setEmail(e.target.value)} 
										required 
										autoComplete="email"
										className={classNames('p-inputtext', { 'p-invalid': submitted && !email })} 
									/>
									<label htmlFor="email">Email</label>
								</span>
								{submitted && !email && <small className="p-error">Email is required.</small>}
							</div>
						</div>

						<div className="flex evenRow">
							<div className="field fullField"  >
								<span className="p-float-label">
									<InputText 
										id="password" 
										type="password"
										value={password} 
										onChange={(e) => setPassword(e.target.value)} 
										required 
										autoComplete="current-password"
										className={classNames('p-inputtext', { 'p-invalid': submitted && !password })} 
									/>
									<label htmlFor="email">Password</label>
								</span>
								{submitted && !password && <small className="p-error">Password is required.</small>}
							</div>
						</div>
						
						<div className="flex  justify-content-center">
							<button className="submitButton flex align-items-center justify-content-center border-none" type="submit">
								Login
							</button>
						</div>

						{displayBadLogin && 
							<div className="flex  justify-content-center red mt-2 boldText" >
								Your user name or password has not been found. Please try again. 
							</div>
						}

						{displayReset && 
							<div className="flex  justify-content-center red mt-2 boldText" >
								We found your account, but due to a recent system upgrade, you will need to reset your password using the link above.
							</div>
						}

					</form>
				:
					<form onSubmit={handleReset}>
						<h2 className="flex justify-content-between align-items-center">Reset Password <Link className="text-base underline text-primary" onClick={() => setPanelView('login')}>Login</Link></h2>

						<div className="flex oddRow">
							<div className="field fullField">

								<span className="p-float-label">
									<InputText 
										id="email" 
										value={email} 
										onChange={(e) => setEmail(e.target.value)} 
										required 
										className={classNames('p-inputtext', { 'p-invalid': submitted && !email })} 
									/>
									<label htmlFor="email">Email</label>
								</span>
								{submitted && !email && <small className="p-error">Email is required.</small>}
							</div>
						</div>

						<ReCaptcha 
							fncCaptchaValidated={fncCaptchaValidated}
							displayCaptchaError={displayCaptchaError}
						/>

						
						<div className="flex justify-content-center">
							<button className="submitButton flex align-items-center justify-content-center border-none" type="submit">
								Reset Password
							</button>
						</div>
					</form>
			}

		</div>
    );
}

export default Login;