import React, { useState, useEffect, useRef }  from 'react';
import { useParams, useNavigate } from 'react-router-dom';
// Needed 3rd party libraries

// Prime React Components
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { InputMask } from 'primereact/inputmask';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';

// Needed Components / Images / Constants
import Subheader from '../components/Subheader'
import { useAuth } from "../hooks/useAuth";


const ResetPassword = () => {
	const toast = useRef(null);
	let { uuid } = useParams();
	const navigate = useNavigate();
	const { updatePassword, checkUUID } = useAuth();
	const [ email, setEmail] = useState('');
	const [ password, setPassword] = useState('');
	const [ confirmPassword, setConfirmPassword] = useState('');
	const [ submitted, setSubmitted ] = useState(false);
	const [ hasErrors, setHasErrors] = useState(false);
	const [isValid, setIsValid] = useState(false);

	useEffect(() => {
		checkUUID(uuid).then(res =>{
			if (res.data.success) {
				setIsValid(true)
				setEmail(res.data.email)
			} else {
				setIsValid(false)
				navigate('/')
			}
		}).catch( err => {
			setIsValid(false)
			navigate('/')
		})
    }, [uuid]);
	
	const fncResetPasswordValidate = async (event) => {
		setSubmitted(true);
		let errors = []
		if (!password.trim() || password.trim().length < 6) errors.push('password')
		
		if (password.trim() !== confirmPassword.trim()) {
			errors.push('emailConfirm')
		}
		
		// We have errors, display correct message and leave
		if (errors.length > 0) {
			setHasErrors(true)
			return
		}

		setHasErrors(false)
		let data = {
          'uuid': uuid,
          'password': password
        }
      let results = await updatePassword(data);
		if (results) {
			toast.current.show({severity:'success', summary: 'Success', detail: 'Your Account has been updated.', life: 3000});
			setSubmitted(false);
			setTimeout(() => {
				navigate('/')
			  }, 3000);
			
		} else {
			toast.current.show({severity:'error', summary: 'Error', detail: 'There was a problem updating your account.', life: 3000});
		}
		
	}

	return (
		 <div className="flex flex-column">
			<Subheader 
				title="Password Reset" 
			/>

			{isValid === true && 
				<div className="flex resetPassword flex-column md:flex-row">
				
					<div className="flex flex-column inputForm py-5 px-3 border-1 border-primary" >
						<form>
							<Toast ref={toast} />
							
							<div className="inputFormFields">
								
								<div className="flex">
									<div className="field fullField"  >
										<span className="p-float-label">
											<InputText 
												id="password" 
												type="password"
												value={password} 
												onChange={(e) => setPassword(e.target.value)} 
												required 
												className={classNames('p-inputtext', { 'p-invalid': submitted && !password })} 
											/>
											<label htmlFor="password">Password</label>
										</span>
										{submitted && (!password || password.trim().length < 6) && <small className="p-error">Password is required.</small>}
									</div>
								</div>

								<div className="flex">
									<div className="field fullField"  >
										<span className="p-float-label">
											<InputText 
												id="confirm_password" 
												type="password"
												value={confirmPassword} 
												onChange={(e) => setConfirmPassword(e.target.value)} 
												required 
												className={classNames('p-inputtext', { 'p-invalid': submitted && !confirmPassword })} 
											/>
											<label htmlFor="confirm_password">Confirm Password</label>
										</span>
										{submitted && !confirmPassword && <small className="p-error">Confirm Password is required.</small>}
									</div>
								</div>
							</div>
							{hasErrors && 
								<div className="flex flex-wrap justify-content-center red mb-2 boldText " >
									Make sure Password is at least 6 characters and Password and Confirm Password match.
								</div>
							}
							<div className="flex justify-content-center">
								<div className="submitButton flex align-items-center justify-content-center" onClick={fncResetPasswordValidate}>
									Update Password
								</div>
							</div>
						</form>
					</div>
				</div>
			}
		</div>
    );
}

export default ResetPassword;