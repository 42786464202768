import React, { useState, useEffect }  from 'react';

// Needed 3rd party libraries
import { useParams  } from 'react-router-dom';
import parse from "html-react-parser";
import dayjs from 'dayjs'
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

// Prime React Components

// Needed Components / Images / Constants
import Subheader from '../components/Subheader'
import PipelineMenu from '../components/PipelineMenu';
import { PAGE_DETAILS } from '../constants/pageConfig';

const Pipeline = (props) => {
	const { pipeline } = props
	let { selectedCategory } = useParams();

	const objPipeline = PAGE_DETAILS['/pipeline'] || {}
	const arrCategories = objPipeline.items || []
	const objCategory = arrCategories.find(cur => cur.link === '/' + selectedCategory) || {}

	const navigate = useNavigate();

	const fncNavigate = (link,linktype) => {
		if (linktype==='i') {
			navigate(link)
		} else {
			window.open(link,'_blank')
		}
		
	}

	return (
        <div className="flex flex-column">
			<Subheader 
				title="Pipeline" 
				description="" 
			/>

			<div className="flex page flex-column md:flex-row">
				<div>
					<div class="flex flex-column subMenu pipeline">
						<div class="flex justify-content-between subMenuRow" onClick={(e) => fncNavigate('/contact','i') }>
							<div class="flex align-items-center">
								<div class="name">Contact Us</div>
							</div>
							<div class="flex align-items-center openIcon">
								<i class="pi pi-chevron-right"></i>
							</div>
						</div>
						<div class="flex justify-content-between subMenuRow" onClick={(e) => fncNavigate('/resources/msl','i') }>
							<div class="flex align-items-center">
								<div class="name">More information</div>
							</div>
							<div class="flex align-items-center openIcon">
								<i class="pi pi-chevron-right"></i>
							</div>
						</div>
						<div class="external-menu">
							<div class="flex justify-content-between subMenuRow" onClick={(e) => fncNavigate('https://neurelis.com/our-pipeline','e') }>
								<div class="flex align-items-center">
									<div class="name">View Our Pipeline</div>
								</div>
								<div class="flex align-items-center openIcon">
									<i class="pi pi-arrow-up-right"></i>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="flex flex-column" style={{width: '100%'}}>
					<p>For more than a decade, Neurelis has been working diligently to develop a pipeline for products and technologies to address significant unmet needs for patients.
 <br/><br/>
					Neurelis' mission is to build a leading neuroscience company that advances the care of patients' suffering from neurological conditions.
 <br/><br/>
					Find our current pipeline on <a target="_blank" href="https://neurelis.com/our-pipeline"> neurelis.com</a></p>
				</div>
			</div>
		</div>
    );
}

export default Pipeline;