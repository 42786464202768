import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const ReCaptcha = (props) => {   
	// PASSED IN PROPERTIES
	const { fncCaptchaValidated, displayCaptchaError} = props
	const captchaRef = useRef(null);

	const fncOnChange = (value) => {
		if (value) {
			fncCaptchaValidated(true)
		}
	}

	return (
		<div className="flex flex-column mt-2">
			{displayCaptchaError && 
				<div className="flex justify-content-center red mb-2 boldText" >
					Please confirm you are not a bot and try again.
				</div>
			}
			<div className="flex justify-content-center red mb-2 boldText" >
				<ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_CODE} ref={captchaRef} onChange={fncOnChange}/>
			</div>
		</div>
    );
}

export default ReCaptcha;