import React, { useState } from "react";
import { Checkbox } from "primereact/checkbox";

const SearchMenu = (props) => {

	const categories = [
        { name: 'Posters', key: 'posters' },
        { name: 'Manuscripts', key: 'manuscripts' },
        { name: 'Slide Decks', key: 'slide' },
        { name: 'Podcasts', key: 'podcast' }
    ];
    const {selectedCategories, setSelectedCategories} = props;

    const onCategoryChange = (e) => {
        let _selectedCategories = [...selectedCategories];

        if (e.checked)
            _selectedCategories.push(e.value);
        else
            _selectedCategories = _selectedCategories.filter(category => category.key !== e.value.key);

        setSelectedCategories(_selectedCategories);
    };

	const fncClear = () => {
		setSelectedCategories([])
	}

		
	return (
		<div className="flex flex-column subMenu contactmenu">
		 	{categories.map((category, index) => {
                return (
                    <div key={index} className="flex align-items-center category-checkbox">
                        <Checkbox inputId={category.key} name="category" value={category} onChange={onCategoryChange} checked={selectedCategories.some((item) => item.key === category.key)} />
                        <label htmlFor={category.key}>
                            {category.name}
                        </label>
                    </div>
                );
            })}
            <button className="bg-transparent border-none uppercase clear-filter-btn" onClick={fncClear} >Clear filters</button>
		</div>		
    );
}

export default SearchMenu;