import React, { useState, useEffect }  from 'react';

// Needed 3rd party libraries
import { useParams  } from 'react-router-dom';
import parse from "html-react-parser";
import dayjs from 'dayjs'

// Prime React Components

// Needed Components / Images / Constants
import Subheader from '../components/Subheader'

const Grants = (props) => {
	const { neurelisEvents } = props
	const upcomingEvents = neurelisEvents.filter((event) => {
	    const eventDate = new Date(event.endDate); 
	    const currentDate = new Date();
	    return eventDate >= currentDate;
	}).sort((a,b) => (new Date(a.endDate) < new Date(b.endDate)?-1:1));

	const pastEvents = neurelisEvents.filter((event) => {
	    const eventDate = new Date(event.endDate);
	    const currentDate = new Date();
	    return eventDate < currentDate;
	}).sort((a,b) => (new Date(a.endDate) < new Date(b.endDate)?-1:1));
	
	return (
        <div className="flex flex-column">
			<Subheader 
				title="Events" 
				subtitle="Please join us at the following meetings" 
			/>

			<div className="flex page events-page flex-column pt-5 pb-8">
				<div>
					<h1 className="text-center mb-6 uppercase">Upcoming Events</h1>
				</div>
				{upcomingEvents && upcomingEvents.length > 0 ?
					<div className="flex flex-wrap mb-8">
						{upcomingEvents.map((cur, index) => {
							return (
								<div key={index} className="flex col-12 md:col-6 lg:col-4 eventCard">
			
									<div className="flex flex-column event w-full border-1 border-round-lg overflow-hidden">
										<div className="relative">
											<img className="m-0 block w-full" src={"/images/events/" + cur.photo} />
											<div className="flex eventDates ">
												<div>
													<i className="pr-2 pi pi-calendar" />
												</div>
												{dayjs(cur.startDate).format('MMMM D')} - {dayjs(cur.endDate).format('MMMM D')}
											</div>
										</div>
										<div className="px-4 py-4">
											<div className="title mb-5">
												{cur.title}
											</div>
											<div >
												<span className="border-1 border-round-lg px-2 py-1 font-bold inline-block">{cur.location}</span> <span className="bg-secondary border-1 border-secondary text-white border-round-lg px-2 py-1 font-bold inline-block">{cur.description}</span>
											</div>
										</div>
										
									</div>
								</div>
							)
						})}
					</div>
					: <p className="text-center">No Upcoming Events</p>
				}		

				<div>
					<h1 className="text-center mb-6 uppercase">Past Events</h1>
				</div>
				{pastEvents && pastEvents.length > 0 ?
					<div className="flex flex-wrap">
						{pastEvents.map((cur, index) => {
							return (
								<div key={index} className="flex col-12 md:col-6 lg:col-4 eventCard">
			
									<div className="flex flex-column event w-full border-1 border-round-lg overflow-hidden">
										<div className="relative">
											<img className="m-0 block w-full" src={"/images/events/" + cur.photo} />
											<div className="flex eventDates ">
												<div>
													<i className="pr-2 pi pi-calendar" />
												</div>
												{dayjs(cur.startDate).format('MMMM D')} - {dayjs(cur.endDate).format('MMMM D')} 
											</div>
										</div>
										<div className="px-4 py-4">
											<div className="title mb-5">
												{cur.title}
											</div>
											<div >
												<span className="border-1 border-round-lg px-2 py-1 font-bold inline-block">{cur.location}</span> <span className="bg-secondary border-1 border-secondary text-white border-round-lg px-2 py-1 font-bold inline-block">{cur.description}</span>
											</div>
										</div>
										
									</div>
								</div>
							)
						})}
					</div>
					: <p className="text-center">No Past Events</p>
				}		

			</div>
		</div>
    );
}

export default Grants;