import React, { useState, useEffect }  from 'react';

// Needed 3rd party libraries
import { useParams  } from 'react-router-dom';
import parse from "html-react-parser";
import dayjs from 'dayjs'

// Prime React Components

// Needed Components / Images / Constants
import Subheader from '../components/Subheader'
import ContactMenu from '../components/ContactMenu';
import ContactForm from '../components/ContactForm';
import { PAGE_DETAILS_CONTACT, RESOURCE_NOTICE } from '../constants/pageConfig';

const Grants = (props) => {
	const { grants } = props
	let { selectedCategory } = useParams();
	const objResources = PAGE_DETAILS_CONTACT['/contactSideMenu'] || {}
	const arrCategories = objResources.items || []
	const objCategory = arrCategories.find(cur => cur.link === '/' + selectedCategory) || {}


	return (
        <div className="flex flex-column">
			<Subheader 
				title="Contact Us" 
			/>

			<div className="flex page contactpage flex-column md:flex-row">
				<div>
					<ContactMenu selectedCategory={selectedCategory} arrCategories={arrCategories} />
				</div>
				<ContactForm />
			</div>
		
		</div>
    );
}

export default Grants;