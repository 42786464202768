import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import axios from 'axios';
import {GET_USER_AUTH, REGISTER_USER_AUTH, UPDATE_USER, RESET_PASSWORD, UPDATE_PASSWORD, CHECK_UUID} from '../constants/URLS';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = async (data) => {
	let serverLogin = await axios({
		method: 'post',
		url: GET_USER_AUTH,
		data:{
			u: data.username,
			p: data.password
		}
	})

	
	if (!serverLogin.data.success) {
		setUser(null);

		if (serverLogin.data.message === 'suggestReset') return 'reset'
		return false
	}

	axios.defaults.headers.common['Authorization'] = serverLogin.data.token;
	setUser({
		token: serverLogin.data.token, 
		userDetails: serverLogin.data.userDetails
	});
    //navigate("/admin");
	return true
  };

  // call this function when you want to register the user
  const register = async (data) => {
	try {
		let serverLogin = await axios({
			method: 'post',
			url: REGISTER_USER_AUTH,
			data: data
		})

		if (!serverLogin.data.success) {
			setUser(null);
			return false
		}

		axios.defaults.headers.common['Authorization'] = serverLogin.data.token;
		setUser({
			token: serverLogin.data.token, 
			userDetails: serverLogin.data.userDetails
		});
		return true

	} catch (err) {
		return false
	}
  };

   // call this function when you want to register the user
   const resetPassword = async (data) => {
		await axios({
			method: 'post',
			url: RESET_PASSWORD,
			data: data
		})
	return true
  };

   // call this function when you want to update the password
   const updatePassword = async (data) => {
	let serverLogin = await axios({
		method: 'post',
		url: UPDATE_PASSWORD,
		data: data
	})

	if (!serverLogin.data.success) {
		return false
	}

	axios.defaults.headers.common['Authorization'] = serverLogin.data.token;
	setUser({
		token: serverLogin.data.token, 
		userDetails: serverLogin.data.userDetails
	});
	
	return true
  };

  const checkUUID = async (uuid) => {
	let isValid = await axios({
		method: 'post',
		url: CHECK_UUID,
		data: {
			uuid: uuid
		}
	})

	return isValid
  }

  // call this function when you want to register the user
  const updateMyAccount = async (data) => {
	let serverLogin = await axios({
		method: 'post',
		url: UPDATE_USER,
		data: data
	})

	if (!serverLogin.data.success) {
		return false
	}

	axios.defaults.headers.common['Authorization'] = serverLogin.data.token;
	setUser({
		token: serverLogin.data.token, 
		userDetails: serverLogin.data.userDetails
	});
	
	return true
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
	axios.defaults.headers.common['Authorization'] = null
    navigate("/", { replace: true });
  };

  const loggedIn = () => {
	if (user && user.userDetails) {
		axios.defaults.headers.common['Authorization'] = user.token;
		return !!user.token && !isTokenExpired(user.token)
  	}
	return false
  }

  const  isTokenExpired = (token) => {
	try {
		const decoded = decode(token);
		if (decoded.exp < Date.now() / 1000) { // Checking if token is expired.
			return true;
		}
		else
			return false;
	}
	catch (err) {
		return false;
	}
}

  const value = useMemo(
    () => ({
      user,
      login,
	  register,
      logout,
	  loggedIn,
	  updateMyAccount,
	  resetPassword,
	  updatePassword,
	  checkUUID
    }),
    [user]
  );

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
	return useContext(AuthContext);
};