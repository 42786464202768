import React, { useState, useEffect }  from 'react';

// Needed 3rd party libraries
import { useParams, Link  } from 'react-router-dom';
import parse from "html-react-parser";
import dayjs from 'dayjs'

// Prime React Components

// Needed Components / Images / Constants
import Subheader from '../components/Subheader'
import GrantsMenu from '../components/GrantsMenu';
import { PAGE_DETAILS, GRANTS_NOTICE } from '../constants/pageConfig';

const Grants = (props) => {
	const { grants } = props
	let { selectedGrant } = useParams();
	const objGrants = PAGE_DETAILS['/grants'] || {}
	const arrCategories = objGrants.items || []
	const objCategory = arrCategories.find(cur => cur.link === '/' + selectedGrant) || {}

	return (
        <div className="flex flex-column grants-main">

			<Subheader 
				title="Grant Applications" 
				category={objCategory.name} 
				description="Neurelis is committed to furthering the goals of various professional, charitable, educational, and patient-focused organizations. In accordance with applicable laws and industry codes of conduct, Neurelis provides support for initiatives that seek to advance medical education, research, and outreach to patients, families, and healthcare providers through external funding opportunities." 
			/>

			<div className="flex page flex-column md:flex-row">
				<div className="desktopOnly">
					<GrantsMenu selectedGrant={selectedGrant} arrCategories={arrCategories} />
				</div>

				{ !objCategory.name && 
							<div>
								At Neurelis, we understand the pivotal role that research and innovation play in advancing the field of healthcare. In our ongoing commitment to support healthcare professionals, researchers, and institutions, we have established this dedicated section to facilitate and streamline the grant application process. Together, we can transform innovative ideas into tangible healthcare advancements. Thank you for considering us as a partner in your research journey, and we look forward to being a catalyst for progress in the world of medical affairs.
							</div>
				}

				{ !objCategory.name && 
					<div className="flex mobileOnly">
						<GrantsMenu selectedGrant={selectedGrant} arrCategories={arrCategories} />
					</div>
				}

				{ objCategory.name && 
				
					<div className="flex flex-column" style={{width: '100%'}}>
						<Link to="/grants" className="backLink mobileOnly">
							← BACK
						</Link>
						<div>
							<h1>{objCategory.name}</h1>

							{objCategory.name === 'Medical Education Grants' && 
								<div className="grants-body-content">
									<p>In alliance with our mission to improve patient care by providing information and education to the medical and broader healthcare communities in the areas of epilepsy and disorders of the central nervous system, we are providing funding opportunities for accredited and non-accredited educational programs for healthcare professionals that are in alignment with our interests.</p>
									 <h2>Submission Guidelines</h2>
									<ul>
										<li>The grant request must be submitted through the online portal by a representative of the organization that is seeking the grant funding.</li>
										<li>Any grant request presented to Neurelis must be unsolicited and submitted by an independent, reputable provider with a history of conducting scientific or educational programs in accordance with all applicable requirements and guidelines</li>
										<li>The program must not be for entertainment or recreational activities</li> 
										 <li>The program must aim to advance medicine, scientific knowledge, provide HCP education, or serve another genuine educational function that benefits patients</li> 
										 <li>The budget submitted for the program must be justified and reflect current fair market values </li> 
										 <li><b>Be prepared to submit:</b>
										 	<ul>
										 		<li>Full Proposal with Needs Assessment</li>
										 		<li>Program Budget</li>
										 		<li>Agenda</li>
										 		<li>Learning Objectives</li>
										 		<li>Plan for measuring success (Outcomes Assessment)</li>
										 	</ul>
										</li>
									 </ul>
									<div class="button-link">
										<Link to="https://www.cybergrants.com/neurelis/eligibilitytoapply" target="_blank">Start New Application</Link>
									</div>

								</div>
							}
							{objCategory.name === 'Healthcare-Related Contributions' && 
								<div className="grants-body-content">
									
									<p>Neurelis will consider contribution requests from professional, patient, and scientific organizations that are registered as tax-exempt under section 501c3 of the Internal Revenue Code. The requesting organization’s mission should be for independent scientific and educational purposes that contribute to the improvement of patient care, education, or advocacy.</p>
									<p><b>Examples of applicable programs include:</b></p>
									<ul>
										<li>Fundraising Events</li>
										<li>Advocacy Programs</li>
										<li>Epilepsy Awareness Community Outreach</li>
										<li>Corporate Sponsorship</li>
									</ul>

									<h2>Submission Guidelines</h2>
									<ul>
										<li>The grant request must be submitted through the online portal by a representative of the organization that is seeking the grant funding.</li>
										<li>Any grant request presented to Neurelis must be unsolicited and submitted by an independent, reputable provider with a history of conducting scientific or educational programs in accordance with all applicable requirements and guidelines</li>
										<li>The program must not be for entertainment or recreational activities</li>
										<li>The program must aim to advance medicine, scientific knowledge, provide HCP education, or serve another genuine educational function that benefits patients </li>
										<li>The budget submitted for the program must be justified and reflect current fair market values </li>
										<li><b>Be prepared to submit:</b>
											<ul>
												<li>Full Proposal with Needs Assessment</li>
												<li>Program Budget</li>
												<li>Agenda</li>
												<li>Goals and Objectives</li>
											</ul>
										</li>
									</ul>

									<div class="button-link">
										<Link to="https://www.cybergrants.com/neurelis/eligibilitytoapply" target="_blank">Start New Application</Link>
									</div>

								</div>
							}
							{objCategory.name === 'Investigator-Initiated Studies' &&
								<div className="grants-body-content">
									
									<p>As part of our commitment to deliver innovative therapies to patients, Neurelis believes in the need to support ethical independent research conducted by qualified third-party investigators in the areas of epilepsy and disorders of the central nervous system.</p>
									<p>This independent research complements Neurelis-sponsored research to explore new opportunities in addressing unmet medical needs. Such clinical research must address meaningful scientific and/or clinical objectives supported by valid study designs in which the privacy rights, safety, and welfare of patients is of primary importance.</p>
									<p>Neurelis investigator-initiated studies must have scientific and medical merit and may be a clinical or non-clinical study conducted without the participation of Neurelis. For these studies, Neurelis may provide funding, product, or both.</p>
									<p>Please note that any materials submitted through the portal will not be treated as confidential or proprietary. By submitting your application for review, you are consenting to this information being classified as non-confidential. If you have any questions regarding this policy, please contact <Link to="mailto:grants@neurelis.com?subject=Grants Inquiry"><b>grants@neurelis.com</b></Link>.</p>


									<h2>Submission Guidelines</h2>
									<ul>
										<li> The grant request must be submitted through the online portal by a representative of the organization that is seeking the grant funding.</li>
										<li>Any grant request presented to Neurelis must be unsolicited and submitted by an independent, reputable organization with a history of conducting research in accordance with all applicable requirements and guidelines</li>
										<li>The study must aim to advance medicine and/or scientific knowledge in the areas of epilepsy and disorders of the central nervous system </li>
										<li>The budget submitted for the program must be justified and reflect current fair market values </li>
										<li><strong>The initial application will consist of a Concept Proposal, outlined below. If Neurelis is interested in the concept, a full proposal will be requested with more detail. </strong></li>
										<li><b>Be prepared to submit:</b>
											<ul>
												<li>Concept Proposal including:
												<ul>
													<li>High level summary of the study’s main scientific question</li>
													<li>Protocol Summary</li>
													<li>Estimated Budget</li>
													<li>Research Design</li>
													</ul>
												</li>
											</ul>
										</li>
									</ul>

									<div class="button-link">
										<Link to="https://www.cybergrants.com/neurelis/IISConcept" target="_blank">Start New Application</Link>
									</div>

								</div>
							}
							{objCategory.name === 'Frequently Asked Questions' && 
							<div className='grants-body-content2'>

								<h2 className="mt-30">Why did the grant process change?</h2>
								<p>Consistent with the 2003 U.S. Office of the Inspector General (OIG) compliance guidance, Neurelis completely separates its grant process from its sales and marketing functions. Grants must never be based on or linked to prescribing, formulary status, purchasing or reimbursement; therefore, Neurelis sales and marketing personnel may not make grant commitments to customers. ALL grant decisions are now centralized through the Grant Review Committee.</p>

								<h2>How can I contact Neurelis regarding my Grant request?</h2>
								<p>Contact Neurelis at <Link to="mailto:grants@neurelis.com?subject=Grants Inquiry"><b>grants@neurelis.com</b></Link> may be initiated via the "Contact Us" navigation above.</p>

								<h2>I've lost my CyberGrants login information. How do I retrieve my username and password? Can I just create a new account?</h2>
								<p>We prefer that you do not create a new account, but rather continue to use the same login you have used to apply before. This will keep your funding history in one place, which will help us review and process your proposals more quickly and effectively. To retrieve your username and password, please email our CyberGrants support team at <Link to="mailto:cgsupport@cybergrants.com?subject=Grants Inquiry"><b>cgsupport@cybergrants.com</b></Link></p>

								<h2>What is a CME provider?</h2>
								<p>Various organizations provide accredited CME/CE opportunities for physicians and other healthcare professionals. CME providers are responsible for the content, quality, and scientific integrity of all accredited CME activities they certify for credit.</p>

								<h2>How will I know if my educational grant will be approved?</h2>
								<p>All requestors will receive a decision via email once the Committee has reviewed the request. Competition for educational grant dollars is very high. The primary focus will be on medical and healthcare related educational activities in the therapeutic areas of epilepsy and central nervous system disorders.</p>

								<h2>Does previous support of a program by Neurelis guarantee future support?</h2>
								<p>No. Each grant is evaluated on its individual merit relative to other grant requests. Please do not consider any request approved until you have received written documentation approving your grant request.</p>

								<h2>What is the policy on websites? Will a grant for the development or maintenance of an educational website get approved?</h2>
								<p>At this time, there is no funding for the development or maintenance of websites. Funding for content development related to a medical or health care related education program that is to be posted on a website for a limited period of time will be considered.</p>
								
								<h2>Who sits on the Grant Review Committee?</h2>
								<p>The grant review committee consists of Neurelis professionals from the medical, legal and regulatory areas of the company.</p>
								
								<h2>My Neurelis representative told me that my grant would be approved but I received a denial. How can that happen?</h2>
								<p>No representative of Neurelis, except Neurelis Grant Review committee personnel, can approve a non-clinical grant. As a requestor, you should not accept any verbal commitments from sales representatives, medical liaisons, or any other Neurelis employee, as they are not authorized to make such a commitment. Please do not consider any grant request approved until you have received written confirmation from the Neurelis Grant Review Committee approving your request.</p>

								<h2>One of my colleagues has requested a grant. Can I get the status of that request?</h2>
								<p>Only the requestor of a specific grant or an alternative contact that has been listed on the grant application by the requestor has authorization to request status updates.</p>

								<h2>Where can I return unused funds?</h2>
								<p>Unused funds, along with a reconciliation of the grant funding used, may be sent to:</p>
									
								
								<p className="ct">Neurelis, Inc.<br></br>
								Attn: Accounts Payable<br></br>
								3430 Carmel Mountain Road<br></br>
								Suite 300<br></br>
								San Diego, CA 92121</p>

								<h2>Will Neurelis accept grant applications for programs or events that have already occurred?</h2>
								<p>Grant applications for programs or events that have already occurred will not be accepted.</p>

							</div>
							}
						</div>
						<div className="grants-notice">
							{GRANTS_NOTICE}
						</div>
					</div>
				}
			</div>
		</div>
    );
}

export default Grants;