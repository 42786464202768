import React, { useState, useEffect } from 'react';

// Needed 3rd party libraries
import classNames from 'classnames';
import { Helmet } from "react-helmet";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

// Prime React Components
import { Menubar } from 'primereact/menubar';
import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';

// Needed Components / Images / Constants
import NeurelisLogo  from '../images/neurelis_logo.png'
import { PAGE_DETAILS, REPORT_MESSAGE } from '../constants/pageConfig';
import { AUTOCOMPLETE } from '../constants/URLS';
import { useAuth } from "../hooks/useAuth";
import { fncGetPDF } from "../services/PDFService"
import SearchResultsItem from "../components/SearchResultsItem"

import ReactGA from "react-ga4";

const Header = (props) => {   
    // PASSED IN PROPERTIES
	const { fncDisplayLoginPanel, fncLogout } = props;

	// CONSTANTS
	const location = useLocation();
	const navigate = useNavigate();
	const { user, loggedIn } = useAuth();
	const isLoggedIn = loggedIn() 

	// STATE VARIABLES
	const [ displaySearch, setDisplaySearch] = useState(false);
	const [ displayMobileMenu, setDisplayMobileMenu ] = useState(false); // boolean to control if we see the mobile menu
	const [ displayMobileSearch, setDisplayMobileSearch ] = useState(false); 
	const [ currentPage, setCurrentPage] = useState('/')
	const [ searchValue, setSearchValue ] = useState('');
    const [ autoCompleteItems, setAutoCompleteItems ] = useState([]);
	// USE EFFECTS
	useEffect(() => {
		setSearchValue('')
    }, [displaySearch]); 

	const onTop = () => {
		setTimeout(() => {
			window.scrollTo(0, 0);
		  }, 200);
	}

	// This will set the current page to a state variable so we can add additional class to the link
	useEffect( () => {
		let _currentPage = location.pathname
		setCurrentPage(_currentPage)
		onTop()
	},[location])

	// Build out our menu using the PAGE_DETAILS constant object. 
	const menuItems = Object.keys(PAGE_DETAILS).reduce((acc,cur) => {
		let objCurPage = PAGE_DETAILS[cur]
		if (objCurPage.topNav === true) {
			
			if (objCurPage.items && objCurPage.items.length > 0) {
				acc.push({
					label: objCurPage.name,
					link: objCurPage.link,
					title: objCurPage.pageTitle,
					items: (objCurPage.items ?
						objCurPage.items.map((curItem, index) => {
							return curItem.internal == true && {
								label: curItem.name,
								title: curItem.pageTitle,
								command:(e) => {
									navigate(cur +  curItem.link)
								}
							}
						})
						: null )
				})
			} else {
				acc.push({
					label: objCurPage.name,
					link: objCurPage.link,
					command:(e) => {
						navigate(objCurPage.link)
					}
				})
			}
			
			
		}
		return acc
	},[])

	// Other Functions
	const searchIcon = <i className="searchIcon pi pi-search ml-4" onClick={(e) => setDisplaySearch(true)}/>

	const menuClassName = classNames('menuBar', {
        'showMenu': !displaySearch,
		'hideMenu': displaySearch
    });

	const searchClassName = classNames('flex justify-content-end searchBar p-input-icon-right', {
        'showSearch': displaySearch,
		'hideSearch': !displaySearch
    });

	const fncAutoCompleteSelect = async (event) => {
		let objCur = event.value.allDetails

		if (objCur.document_type === 'html' || objCur.document_type === 'external') {
			window.open(objCur.url,'_blank')

			ReactGA.event(
				"search_results_autocomplete", { "url":objCur.url} 
			  );


		} else if (objCur.document_type === 'page') {
			let objURL = new URL(objCur.url)
			navigate(objURL.pathname)

			ReactGA.event(
				"search_results_autocomplete", { "page":objURL.pathname} 
			  );


		} else {
			//objCur.searchID
			let results = await fncGetPDF(objCur.searchID)

			ReactGA.event(
				"search_results_autocomplete", { "pdf":objCur.searchID} 
			  );

			if (results === 'no-token') {
				fncDisplayLoginPanel('register')
			}
		}

		setSearchValue('')
		setDisplaySearch(false)
	}

	const fncSearch = () => {
		navigate('/search?s=' + searchValue)
		setSearchValue('')
		setDisplaySearch(false)
		setDisplayMobileSearch(false)
	}

	const fncAutoComplete = async (event) => {
		if (event.query.length < 3) return 

	

		  ReactGA.event(
			"view_search_results_autocomplete", { "search_term":event.query.toLowerCase()} 
		  );

	

		let searchXHR = await axios({method: 'get', url: AUTOCOMPLETE + '?searchTerm=' + event.query.toLowerCase()})
		let _searchTitleResults = searchXHR.data.results.map(cur => {
			return {
				name: cur.title, 
				allDetails: cur
			}
		})
		setAutoCompleteItems(_searchTitleResults);
    }

	return (
       <header className="flex flex-column header">
       			{PAGE_DETAILS[currentPage] && 
					<Helmet>
						<title>{PAGE_DETAILS[currentPage].pageTitle}</title>
						<meta name="description" content={PAGE_DETAILS[currentPage].pageDescription} />
						<meta name="title" content={PAGE_DETAILS[currentPage].pageTitle} />
					</Helmet>
				}
       		{Object.keys(PAGE_DETAILS).map((item, index) => (
				<React.Fragment key={'helmetwrapper_' + index}>
					{PAGE_DETAILS[item].items &&
						<>
							{PAGE_DETAILS[item].items.map((curItem, innerIndex) => (
								<React.Fragment key={'helmetwrapper2_' + innerIndex}>
								{ curItem.link === '/' + currentPage.substring(currentPage.lastIndexOf('/') + 1) &&
									<Helmet key={'helmet_' + innerIndex}>
										<title>{curItem.pageTitle}</title>
										<meta name="description" content={curItem.pageDescription} />
										<meta name="title" content={curItem.pageTitle} />
									</Helmet>

								}
								</React.Fragment>
							))}
						</>}
				</React.Fragment>
			),[])}
       	
			<div className="flex justify-content-between">
				{!displayMobileSearch && 
					<Link to="/" className="logo" onClick={(e) => setDisplayMobileMenu(!displayMobileMenu) }>
						<img src={NeurelisLogo} alt="Neurelis Logo" title=""  />
						<span className="desktopOnly">Medical Affairs Resource Center</span>
					</Link>
				}
				<div className="desktopOnly">
					<div className={menuClassName}>
						<Menubar model={menuItems}  end={searchIcon}/>
					</div>
					<div className={searchClassName} >
					
						<AutoComplete 
							value={searchValue} 
							field="name"
							suggestions={autoCompleteItems} 
							onChange={(e) => setSearchValue(e.value)} 
							completeMethod={fncAutoComplete}
							onSelect={fncAutoCompleteSelect}
							minLength="3"
							panelClassName="autoCompletePanel"
							itemTemplate={SearchResultsItem}
						/>
						{searchValue !== "" && 
							<i className="pi pi-times link" onClick={(e) => setDisplaySearch(false)} />
						}
						<Button className="ml-2" icon="pi pi-search" onClick={fncSearch} />
					</div>
					
				</div>
				{ !isLoggedIn && 
					<div className="flex headerButtons desktopOnly">
						<div className="mr-2">
							<Button text severity="secondary" onClick={(e) => fncDisplayLoginPanel('login')}>
								<i className="pi pi-sign-in flipIcon mr-1" />
								Login
							</Button>
						</div>
						<div>
							<Button severity="secondary" icon="pi pi-user" label="Register"  outlined onClick={(e) => fncDisplayLoginPanel('register')} />
						</div>
					</div>
				}
				{ isLoggedIn && 
					<div className="flex headerButtons desktopOnly">
						<div className="flex  mr-2 mt-2">
							Welcome {user.userDetails.first_name}!
							<Link className="ml-3 mr-1 text-black" to="/my-account">My Account</Link>
						</div>
						<div>
							<Button severity="secondary" icon="pi pi-user" label="Logout"  outlined onClick={(e) => fncLogout()} />
						</div>
					</div>
				}
				
				{!displayMobileSearch && 
					<div className="flex mobileOnly">
						<div className="flex mobileExpanders mr-2">
							<i className="pi pi-search" onClick={(e) => setDisplayMobileSearch(true) }/>
						</div>
						<div className="flex mobileExpanders">
							<i className="pi pi-bars" onClick={(e) => setDisplayMobileMenu(!displayMobileMenu) }/>
						</div>
					</div>
				}
			</div>
			{displayMobileMenu && !displayMobileSearch && 
				<div className="flex flex-column mobileOnly mb-4 mt-3">
					{menuItems.map((cur, index) => {
						return (
							<Link key={index} to={cur.link}>
								<div className="flex justify-content-between align-items-center mobileMenuRow" key={"navKey-" + cur.label} onClick={(e) => setDisplayMobileMenu(!displayMobileMenu) }>
									<div className="menuName">
										{cur.label}
									</div>
									<div>
										<i className="pi pi-chevron-right" />
									</div>
								</div>
							</Link>
						)
					})}
					{ !isLoggedIn && 
						<div className="flex headerButtons justify-content-center">
							<div className="mr-2">
								<Button text severity="secondary" onClick={(e) => fncDisplayLoginPanel('login')} >
									<i className="pi pi-sign-in flipIcon mr-1" />
									Login
								</Button>
							</div>
							<div>
								<Button severity="secondary" icon="pi pi-user" label="Register"  outlined onClick={(e) => fncDisplayLoginPanel('register')} />
							</div>	
						</div>
					}
					{ isLoggedIn && 
						<div className="flex headerButtons desktopOnly">
							<div className="flex  mr-2 mt-2">
								Welcome {user.userDetails.first_name}!
								<Link className="ml-3 mr-1 text-black" to="/my-account">My Account</Link>
							</div>
							<div>
								<Button severity="secondary" icon="pi pi-user" label="Logout"  outlined onClick={(e) => fncLogout()} />
							</div>
						</div>
					}
					<div className="flex reportMessage">
						{REPORT_MESSAGE}
					</div>
				</div>
			}

			{displayMobileSearch && 
				<div className="flex mobileOnly" >
					<div className="flex justify-content-end searchBar p-input-icon-right" >
						<AutoComplete 
							value={searchValue} 
							field="name"
							suggestions={autoCompleteItems} 
							onChange={(e) => setSearchValue(e.value)} 
							completeMethod={fncAutoComplete}
							onSelect={fncAutoCompleteSelect}
							panelClassName="autoCompletePanel"
						/>
						<i className="pi pi-times" onClick={(e) => setDisplayMobileSearch(false)} />
						<Button className="ml-2" icon="pi pi-search" onClick={fncSearch} />
					</div>
				</div>
			}
			
	   </header>
    );
}

export default Header;