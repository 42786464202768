import React, { useState } from 'react';

import { InputText } from "primereact/inputtext"

const Subheader = (props) => {   
	// PASSED IN PROPERTIES
	const { category, title, subtitle, description, fncSearch, searchTerm} = props

	//const [searchTerm, setSearchTerm] = useState([]); 

	const drawBreadCrumbs = () => {
		let arrBreadCrumbs = ['Home', title]
		if (category) arrBreadCrumbs.push(category)

		return (
			<div className="flex align-items-center breadcrumbs ">
				{arrBreadCrumbs.map((cur, index) => {
					if (index + 1 < arrBreadCrumbs.length) {
						return (
							<div key={index} className="white-space-nowrap">
								<span className="grayText">{cur} <i className="ml-2 mr-2">→</i></span>
							</div>
						)
					} else {
						return (
							<div key={index} className="text-overflow-ellipsis white-space-nowrap overflow-hidden">{cur}</div>
						)
					}
				})}
			</div>
		)
	}
	
	return (
		<>
			<div className="flex flex-column subheader mobileOnly">
				{drawBreadCrumbs()}
				{!category && 
					<div className="flex flex-column justify-content-center blue">
						<div className="flex justify-content-center">
							<h1>{title}</h1>
						</div>
						{fncSearch && 
							<div className="flex justify-content-center">
								<InputText 
									id="search"
									value={(searchTerm?searchTerm:'')}
									onChange={(e) => fncSearch(e.target.value)} 
									className="p-inputtext"
									style={{width:"400px"}}
								/>
							</div>
						}
						{subtitle && 
							<div className="flex justify-content-center">
								<h2>{subtitle} </h2>
							</div>
						}
						{description && 
							<div className="flex justify-content-center description">
								{description}
							</div>
						}
					</div>
				}
			</div>
			<div className="flex flex-column subheader desktopOnly">
				<div className="flex flex-column justify-content-center blue">
					<div className="flex justify-content-center">
						<h1>{title}</h1>
					</div>
					{fncSearch && 
						<div className="flex justify-content-center">
							<InputText 
								id="search"
								value={(searchTerm?searchTerm:'')}
								onChange={(e) => fncSearch(e.target.value)} 
								className="p-inputtext"
								style={{width:"400px"}}
							/>
						</div>
					}
					{subtitle && 
						<div className="flex justify-content-center">
							<h2>{subtitle} </h2>
						</div>
					}
					<div className="flex justify-content-center description">
						{description}
					</div>
				</div>
				{drawBreadCrumbs()}
			</div>
		</>
    );
}

export default Subheader;