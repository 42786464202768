import React from 'react';
import classNames from 'classnames';
import { useNavigate, Link } from 'react-router-dom';

import NeurelisIcon from './NeurelisIcon';

const ContactMenu = (props) => {   
		
	const { arrCategories } = props

	return (
		<div className="flex flex-column subMenu contactmenu">

			{arrCategories.map((cur, index) => {
				return (
					<div key={index} className="flex justify-content-between subMenuRow">
						<Link to={cur.link} className="flex justify-content-between subMenuRow p-0">
							<div className="flex" >
								<NeurelisIcon icon={cur.icon}/>
								<div className="info-group">
									<div className="name">{cur.name}</div>
									<div className="description">{cur.description}</div>
								</div>
							</div>
							<div className="flex align-items-center openIcon">
								{(cur.internal ? 
									<i className="pi pi-chevron-right" />
								:
									<i className="pi pi-chevron-right" />
								)}
							</div>
						</Link>
					</div>
				)
			})}
		</div>		
    );
}

export default ContactMenu;