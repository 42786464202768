import React, { useState, useRef } from "react";
import { useSearchParams } from 'react-router-dom';

import { useFormik } from 'formik';
import axios from 'axios';
import { classNames } from 'primereact/utils';
import { InputText } from "primereact/inputtext";
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from "primereact/inputtextarea";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

import ReCaptcha from '../components/ReCaptcha'

import ReactGA from "react-ga4";

import { MIRF_REQUEST } from '../constants/URLS';
import { useAuth } from "../hooks/useAuth";
import { STATE_DROPDOWN, CREDENTIALS_DROPDOWN} from "../constants/registerUserData"

const RequestForm = (props) => { 
	const toast = useRef(null);
	const { fncDisplayLoginPanel } = props;
	const user = JSON.parse(localStorage.getItem("user"));

	const { loggedIn } = useAuth();
	const isLoggedIn = loggedIn()
	const [queryParameters] = useSearchParams()

	const [ submitted, setSubmitted ] = useState(false);
	const [ pageView, setPageView ] = useState('request')
	const [ucred, setCred] = useState(isLoggedIn?user.userDetails.credentials:'');
	const [unpi, setNpiNumber] = useState(isLoggedIn?user.userDetails.npi_number:'');
	const [ufirstname, setFirstName] = useState(isLoggedIn?user.userDetails.first_name:'');
	const [ulastname, setLastName] = useState(isLoggedIn?user.userDetails.last_name:'');

	const [udegree, setDegree] = useState(isLoggedIn?user.userDetails.credentials:'');
	const [uinit, setInit] = useState(isLoggedIn?user.userDetails.institution:'');
	const [uaddress, setAddress] = useState('');
	const [ucity, setCity] = useState('');
	const [uzipcode, setCode] = useState(null);
	const [uemail, setEmail] = useState(isLoggedIn?user.userDetails.email:'');
	const [uphone, setPhone] = useState(isLoggedIn?user.userDetails.phone:'');
	const [ustate, setState] = useState(isLoggedIn?user.userDetails.state:'');
	const [umessage, setMessage] = useState('');
	const [udocument, setDocument] = useState('');
	const [hasErrors, setHasErrors] = useState(false);
	
	const [ufullname, setFullName] = useState(isLoggedIn?ufirstname + ' ' + ulastname:'');

	const fncRegisterValidate = async (event) => {
		setSubmitted(true);
		let errors = []
		//if (!ucred.trim()) errors.push('credentials')
		if (!ufullname?.trim()) errors.push('full_name')
		if (!udegree?.trim()) errors.push('degree')
		if (!uinit?.trim()) errors.push('institution')
		if (!uemail?.trim()) errors.push('email')
		if (!ustate?.trim()) errors.push('state')
		// Validate phone number
		if (!uphone?.trim() || !/\(\d{3}\) \d{3}-\d{4}/.test(uphone)) {
			errors.push('phone');
			setPhone(uphone.replace(/\D/g, '')) // Remove all non-numeric characters
		}

		// in order to trim, conver to string first
		if (!unpi?.toString().trim()) errors.push('npi_number')
		if (!uzipcode?.toString().trim()) errors.push('zip_code')
		// if (!udocument.trim()) errors.push('document')
		
		// We have errors, display correct message and leave
		if (errors.length > 0) {
			console.log('Current fields with validation errors: ', errors)
			setHasErrors(true)
			return
		}

		setHasErrors(false)
		let data = {
          'credentials': ucred,
          'npi_number': unpi,
          'full_name': ufullname,
          'degree': udegree,
          'institution': uaddress,
          'address': uinit,
          'city': ucity,
          'zip_code': uzipcode,
          'email': uemail,
          'phone': uphone,
          'state': ustate,
          'message': umessage,
          'document': udocument,
        }
      	
		let mirfResults = await axios({
			method: 'post', 
			url: MIRF_REQUEST,
			data: data,
		})

		if (mirfResults.data.success) {

			ReactGA.event("MIRF Form Submission");

			setPageView('confirmation')
			
		} else {
			toast.current.show({ severity: 'error', summary: 'Error', detail: 'There was an issue, please try again later.', life: 3000 });
		}
		
	}

	return (
		 <div className="flex flex-column my-account" style={{ width: '100%'}}>
			<Toast ref={toast} />
			{pageView === 'request' && 

				<div className="inputForm">
					{ !isLoggedIn && 
						<div className="border-1 border-primary py-4 px-4 flex">
							<div className="w-27rem m-auto">
								<h3 className="mb-3 text-primary">Registration Required</h3>
								<p className="font-semibold">Registration is required to submit a Medical Information Request Form. Please log in to your account or complete the registration form to create a new account.</p>
								<div className="flex registration-required-header headerButtons">
									<div className="mr-2 flex-auto">
										<Button className="w-full text-center" text severity="secondary" onClick={(e) => fncDisplayLoginPanel('login')} >
											Login
										</Button>
									</div>
									<div className="flex-auto">
										<Button className="w-full text-center" severity="secondary" label="Register" outlined onClick={(e) => fncDisplayLoginPanel('register')} />
									</div>	
								</div>
							</div>
						</div>
					}
					<form className={` ${ !isLoggedIn ? "locked" : "" } submit-question-form`}>
						{ !isLoggedIn ? 
							<div className="overlay"></div>
						: ''
						}
						<div>
							<Toast ref={toast} />
					
							<h1 className="mb-4 pl-2">Submit a Request</h1>
							
							<div className="grid">

								<div className="col-12 md:col-6 xl:col-6 mb-2">
									<div className="field fullField"  >
										<span className="p-float-label">
											<InputText 
												id="full_name" 
												value={ufullname} 
												onChange={(e) => setFullName(e.target.value)}
												required 
												className={classNames('p-inputtext', { 'p-invalid': submitted && !ufullname })} 
											/>
											<label htmlFor="email">Full Name*</label>
										</span>
										{submitted && !ufullname && <small className="p-error">Full Name is required.</small>}
									</div>
								</div>

								<div className="col-12 md:col-6 xl:col-6 mb-2">
									<div className="field fullField">
										<span className="p-float-label">
											<InputText 
												id="degree" 
												value={udegree} 
												onChange={(e) => setDegree(e.target.value)}
												required 
												className={classNames('p-inputtext', { 'p-invalid': submitted && !udegree })} 
											/>
											<label htmlFor="email">Degree*</label>
										</span>
										{submitted && !udegree && <small className="p-error">Degree is required.</small>}
									</div>
								</div>

								<div className="col-12 mb-2">
									<div className="field fullField">
										<span className="p-float-label">
											<InputText 
												id="institution" 
												value={uinit} 
												onChange={(e) => setInit(e.target.value)}
												required 
												className={classNames('p-inputtext', { 'p-invalid': submitted && !uinit })} 
											/>
											<label htmlFor="email">Institution/Office*</label>
										</span>
										{submitted && !uinit && <small className="p-error">Institution is required.</small>}
									</div>
								</div>

								<div className="col-12 mb-2">
									<div className="field fullField">
										<span className="p-float-label">
											<InputText 
												id="address" 
												value={uaddress} 
												onChange={(e) => setAddress(e.target.value)}
											/>
											<label htmlFor="email">Address</label>
										</span>
									</div>
								</div>

								<div className="col-12 md:col-4 xl:col-4 mb-2">
									<div className="field fullField">
										<span className="p-float-label">
											<InputText 
												id="city" 
												value={ucity} 
												onChange={(e) => setCity(e.target.value)}
											/>
											<label htmlFor="email">City</label>
										</span>
									</div>
								</div>

								<div className="col-12 md:col-4 xl:col-4 mb-2">
									<div className="field fullField"  >
										<span className="p-float-label">
											<Dropdown 
												value={ustate}
												onChange={(e) => setState((prev) => (prev = e.value))} 
												options={STATE_DROPDOWN} 
												required
												className={classNames({ 'p-invalid': submitted && !ustate })} 
											/>
											<label htmlFor="email">State*</label>
										</span>
										{submitted && !ustate && <small className="p-error">State is required.</small>}
									</div>
								</div>

								<div className="col-12 md:col-4 xl:col-4 mb-2">
									<div className="field fullField"  >
										<span className="p-float-label">
											<InputNumber 
												id="zip_code" 
												useGrouping={false}
												value={uzipcode}
												onValueChange={(e) => setCode(e.value)}
												required
												className={classNames('p-inputnumber', { 'p-invalid': submitted && !uzipcode })} 										
											/>
											<label htmlFor="email">ZIP Code*</label>
										</span>
										{submitted && !uzipcode && <small className="p-error">ZIP CODE is required.</small>}
									</div>
								</div>

								<div className="col-12 md:col-4 xl:col-4 mb-2">
									<div className="field fullField"  >
										<span className="p-float-label">
											<InputText 
												id="email" 
												value={uemail} 
												onChange={(e) => setEmail(e.target.value)}
												required 
												autoComplete="email"
												className={classNames('p-inputtext', { 'p-invalid': submitted && !uemail })} 
											/>
											<label htmlFor="email">Email*</label>
										</span>
										{submitted && !uemail && <small className="p-error">Email is required.</small>}
									</div>
								</div>

								<div className="col-12 md:col-4 xl:col-4 mb-2">
									<div className="field fullField"  >
										<span className="p-float-label">
											<InputMask
												id="phone"
												value={uphone} 
												onChange={(e) => setPhone(e.value)} 
												validateOnly	
												autoComplete="phone"
												mask="(999) 999-9999"
												className={classNames('p-inputmask', { 'p-invalid': submitted && !uphone })} 
											/>
											<label htmlFor="email">Phone Number*</label>
										</span>
										{submitted && !uphone && <small className="p-error">Phone Number is required.</small>}
									</div>
								</div>

								<div className="col-12 md:col-4 xl:col-4 mb-2">
									<div className="field fullField"  >
										<span className="p-float-label">
											<InputNumber 
												id="npi_number" 
												useGrouping={false}
												value={unpi}
												onValueChange={(e) => setNpiNumber(e.value)}
												required
												className={classNames('p-inputnumber', { 'p-invalid': submitted && !unpi })} 

											/>		
											<label htmlFor="email">NPI Number*</label>
										</span>
										{submitted && !unpi && <small className="p-error">NPI Number is required.</small>}							
									</div>
								</div>
								{ !queryParameters.get("s") && 
									<div className="col-12 mb-2">
										<div className="field fullField">
											<span className="p-float-label">
												<InputTextarea 
													id="description"
													value={umessage} 
													onChange={(e) => setMessage(e.target.value)} 
													rows={5} cols={30} 
													className="w-12"
												/>
												<label htmlFor="email">Your Request</label>
											</span>	
										</div>
									</div>
								}
								<div className="col-12 px-4 mb-2">
									<p><b><i>In accordance with the Federal Open Payments/Sunshine Act, the provision of pay-to-access reprints to U.S. licensed physicians is a reportable transfer of value.</i></b></p>
								</div>

								{ queryParameters.get("s") && 
									<div className="col-12 mb-2">
										<div className="field fullField"  >
											<span className="p-float-label">
												<InputText 
													id="document" 
													value={queryParameters.get("s") ? queryParameters.get("s") : udocument } 
													onChange={(e) => setDocument(e.target.value)}
													// className={classNames('p-inputtext', { 'p-invalid': submitted && !udocument })} 
												/>
												<label htmlFor="email">Document</label>
											</span>	
										</div>
									</div>
								}
					
							</div>
							{hasErrors && 
								<div className="flex  justify-content-center red mb-2 boldText" >
									Please fill in all required fields.
								</div>
							}
							<div className="flex justify-content-center">
								<div className="submitButton flex align-items-center justify-content-center" onClick={fncRegisterValidate}>
									Submit
								</div>
							</div>
						</div>
					</form>
				</div>
			}

			{pageView === 'confirmation' && 
				<div className="flex confirmation justify-content-center pb-4" >
					Thank you for submitting!
				</div>
			}
		</div>
    );
}

export default RequestForm;