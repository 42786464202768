import axios from 'axios';
import { GET_PDF, CHECK_PDF } from '../constants/URLS';

export const fncGetPDF = async (resourceID) => {
	try {
		//first step, make a quick call and make sure that we have a PDF to give and that the user is logged in for locked ones
		let checkXHR = await axios( CHECK_PDF + '/' + resourceID, {
			method: 'GET',
		})

		if (!checkXHR.data || !checkXHR.data.success) {
			if (checkXHR.data && checkXHR.data.errcode) return checkXHR.data.errcode
			return 'unknown'
		}
		
		let pdfXHR = await axios( GET_PDF + '/' + resourceID, {
			method: 'GET',
			responseType: 'arraybuffer' //Force to receive data in a Blob Format
		})

		const file = new Blob(
			[pdfXHR.data], 
			{type: 'application/pdf'});
			

		// IE
		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(data, filename);
			return;
		}
		
		const fileUrl = URL.createObjectURL(file);
		const w = window.open(fileUrl);
		w && w.focus();
	} catch (err) {
		debugger
	}
 }