import React, { useState, useEffect } from "react";
import dayjs from 'dayjs'
import { Link, useNavigate } from 'react-router-dom';

import classNames from 'classnames';
import axios from 'axios';

// Prime React Components
import { AutoComplete } from 'primereact/autocomplete';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

// Needed Components
import HomeSlider from '../components/HomeSlider'
import Resource from '../components/Resource';
import MedicalGrantForm from '../components/MedicalGrantForm';

import PodcastIcon from '../images/podcasts-icon.svg'
import PodcastImage from '../images/podcasts-feature-img.jpeg'

import SubstractIcon from '../images/subtract-icon.svg'
import PolygonIcon from '../images/polygon-icon.svg'
import ApprovedIcon from '../images/approved-icon.svg'
import EducationIcon from '../images/education-icon.svg'
import { AUTOCOMPLETE } from '../constants/URLS';
import { fncGetPDF } from "../services/PDFService"
import SearchResultsItem from "../components/SearchResultsItem"

import ReactGA from "react-ga4";

const Home = (props) => {

	const { neurelisEvents, podcasts, neurelisImages, fncDisplayLoginPanel } = props

	const upcomingEvents = neurelisEvents.filter((event) => {
			    const eventDate = new Date(event.endDate); 
	    const currentDate = new Date();
	    return eventDate >= currentDate;
	}).sort((a,b) => (new Date(a.endDate) < new Date(b.endDate)?-1:1))

	const navigate = useNavigate();
	const [ displaySearch, setDisplaySearch] = useState(false);
	const [ searchValue, setSearchValue ] = useState('');
    const [ autoCompleteItems, setAutoCompleteItems ] = useState([]);
	

    // USE EFFECTS
	useEffect(() => {
		setSearchValue('')
    }, [displaySearch]); 

    const searchClassName = classNames('w-full searchBar p-input-icon-right', {
        'showSearch': displaySearch,
		'hideSearch': !displaySearch
    });

	const fncAutoCompleteSelect = async (event) => {
		let objCur = event.value.allDetails
		
		if (objCur.document_type === 'html' || objCur.document_type === 'external') {
			window.open(objCur.url,'_blank')

			ReactGA.event(
				"search_results_autocomplete", { "url":objCur.url} 
			  );


		} else if (objCur.document_type === 'page') {
			let objURL = new URL(objCur.url)
			navigate(objURL.pathname)

			ReactGA.event(
				"search_results_autocomplete", { "page":objURL.pathname} 
			  );


		} else {
			//objCur.searchID
			let results = await fncGetPDF(objCur.searchID)

			ReactGA.event(
				"search_results_autocomplete", { "pdf":objCur.searchID} 
			  );

			if (results === 'no-token') {
				fncDisplayLoginPanel('register')
			}
		}

		setSearchValue('')
		setDisplaySearch(false)
	}


	const fncAutoComplete = async (event) => {
		if (event.query.length < 3) return 
		let searchXHR = await axios({method: 'get', url: AUTOCOMPLETE + '?searchTerm=' + event.query.toLowerCase()})

		ReactGA.event(
			"view_search_results_autocomplete", { "search_term":event.query.toLowerCase()} 
		  );

		let _searchTitleResults = searchXHR.data.results.map(cur => {
			return {
				name: cur.title, 
				allDetails: cur
			}
		})
		setAutoCompleteItems(_searchTitleResults);
    }
	

    return (
        <>
			<HomeSlider neurelisImages={neurelisImages} />

			<div className="search-resources text-center">
				<h2>Search resources</h2>
				<p>Search our resources by entering a keyword in the field below.</p>
				<div className="seach-section flex justify-content-center">
		            {/*<AutoComplete placeholder="Search Medical Affairs" value={value} suggestions={items} completeMethod={search} onChange={(e) => setValue(e.value)} />*/}
					<div className={searchClassName}>
			            <AutoComplete 
							value={searchValue} 
							field="name"
							placeholder="Search Medical Affairs"
							suggestions={autoCompleteItems} 
							onChange={(e) => setSearchValue(e.value)} 
							completeMethod={fncAutoComplete}
							onSelect={fncAutoCompleteSelect}
							minLength="3"
							panelClassName="autoCompletePanel"
							itemTemplate={SearchResultsItem}
						/>
		        	</div>
		        </div>
	        </div>
	        
	        {podcasts[0] &&
		       	<div className="latest-podcasts-section bg-white">
		       		<div className="container">
		       			<div className="grid flex-column-reverse md:flex-row">
		       				<div className="col-12 md:col-6">
		       					<div className="podcast-image">
		       						<div className="icon"><img src={PodcastIcon} /></div>
		       						<img src={PodcastImage} />
		       						<div className="info">
		       							<p>{podcasts[0].title}</p>
		       						</div>
		       					</div>
		       					<div className="md:hidden"><Link to="/resources/podcasts" className="all-btn-link">All the Podcasts</Link></div>
		       				</div>
		       				<div className="col-12 md:col-6">
		       					<div className="content">
		       						<h2>New Podcast</h2>
		       						<p>Play or download the latest podcast on overcoming the challenges of developing an intranasal diazepam rescue therapy for the treatment of seizure clusters.</p>
		       						<div className="hidden md:block"><Link to="/resources/podcasts" className="all-btn-link">All the Podcasts</Link></div>
		       					</div>
		       				</div>
		       			</div>
		       		</div>
		       	</div>
	       	}

	       	{upcomingEvents[0] &&
		       	<div className="latest-events-section bg-white">
		       		<div className="container">
		       			<h2 className="text-center">Events</h2>
						<div className="flex flex-column text-center">

							<div className="event-info">
								<p>Our next event is the {upcomingEvents[0].title} from {dayjs(upcomingEvents[0].startDate).format('MMMM D')} through {dayjs(upcomingEvents[0].endDate).format('MMMM D')} in {upcomingEvents[0].location}</p>
							</div>

							<div className="events-main-feature">
								<img src={"/images/events/" + upcomingEvents[0].photo} />
							</div>

							<Link to="/events" className="all-btn-link">view all events</Link>
							
						</div>
		       		</div>
		       	</div>
	       	}

	       	<div className="grants-section">

	       		<div className="icon1">
	       			<img src={SubstractIcon} />
	       		</div>
	       		<div className="icon2">
	       			<img src={SubstractIcon} />
	       		</div>
	       		<div className="icon3">
	       			<img src={SubstractIcon} />
	       		</div>
	       		<div className="icon4">
	       			<img src={PolygonIcon} />
	       		</div>
	       		<div className="icon5">
	       			<img src={ApprovedIcon} />
	       		</div>

	       		<div className="container">
	       			<div className="grid">
	       				<div className="col-12 md:col-4 sm:col-12">
	       					<div className="medical-form-section">
	       						<div className="icon">
	       							<img src={EducationIcon} />
	       							<h3>Neurelis Medical Education Grant</h3>
	       							<h5 className="form-title">for</h5>

	       							<MedicalGrantForm />

	       						</div>
	       					</div>
	       				</div>
	       				<div className="col-12 md:col-8 sm:col-12">
	       					<div className="content">
	       						<h2>Grants</h2>
	       						<p>Neurelis provides support for initiatives that seek to advance medical education, research, and outreach to patients, families, and healthcare providers through external funding opportunities.</p>
	       						<Link to="/grants/medical-education" className="all-btn-link">View Grants Options</Link>
	       					</div>
	       				</div>
	       			</div>
	       		</div>
	       	</div>


        </>
    );
}

export default Home;