import React, { useState, useRef } from "react";
import { Link, useNavigate } from 'react-router-dom';

import { useFormik } from 'formik';
import axios from 'axios';

import { InputText } from "primereact/inputtext";
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from "primereact/inputtextarea";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

import ReCaptcha from '../components/ReCaptcha'

import { CONTACT_US } from '../constants/URLS';
import { useAuth } from "../hooks/useAuth";
import { STATE_DROPDOWN } from "../constants/registerUserData"

import ReactGA from "react-ga4";

const ContactForm = () => { 
	const toast = useRef(null);
	const user = JSON.parse(localStorage.getItem("user"));
	const { loggedIn } = useAuth();
	const isLoggedIn = loggedIn()

    const radioButton = [
        { name: 'Yes', value: 'Y' },
        { name: 'No', value: 'N' }
    ];

	const [ pageView, setPageView ] = useState('contact')
	// NEEDED FOR ReCaptcha
	const [ captchaValidated, setCaptchaValidated ] = useState(false);
	const [ displayCaptchaError, setDisplayCaptchaError ] = useState(false);
	
	const [ufullname, setFullName] = useState(isLoggedIn?user.userDetails.first_name + ' ' + user.userDetails.last_name:'');
	const [uinit, setInit] = useState(isLoggedIn?user.userDetails.institution:'');
	const [udegree, setDegree] = useState(isLoggedIn?user.userDetails.degree:'');
	const [uaddress, setAddress] = useState(isLoggedIn?user.userDetails.address:'');
	const [ucity, setCity] = useState(isLoggedIn?user.userDetails.city:'');
	const [uzipcode, setCode] = useState(isLoggedIn?user.userDetails.zipcode:null);
	const [uemail, setEmail] = useState(isLoggedIn?user.userDetails.email:'');
	const [uphone, setPhone] = useState(isLoggedIn?user.userDetails.phone:'');
	const [ustate, setState] = useState(isLoggedIn?user.userDetails.state:'');
	const [umessage, setMessage] = useState(isLoggedIn?user.userDetails.message:'');

	const [ submitted, setSubmitted ] = useState(false);

	const fncCaptchaValidated = () => {
		setCaptchaValidated(true)
		setDisplayCaptchaError(false)
	}
	// END OF ReCaptcha

    const { handleBlur, handleSubmit, errors, touched, getFieldProps, handleChange, setFieldValue } = useFormik({
        initialValues: {
            full_name: '',
            degree: '',
            institution: '',
            address: '',
            city: '',
            states: '',
            zipcode: '',
            email: '',
            phone: '',
            medical: '',
            message: '',
        },
        validate: (values) => {
            let errors = {};
			setSubmitted(true);

            if (!ufullname) {
                errors.ufullname = 'Required';
            } 
            if (!udegree) {
                errors.udegree = 'Required';
            } 
            if (!uinit) {
                errors.uinit = 'Required';
            } 
            if (!uemail) {
                  errors.uemail = 'Required';
            } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                    uemail
                  )
            ) {
                  errors.uemail = 'Invalid email address';
            }

            return errors;
        },
        onSubmit: async (values) => {
        	let data = {
	          'full_name': ufullname,
	          'degree': udegree,
	          'institution': uaddress,
	          'address': uinit,
	          'city': ucity,
	          'zip_code': uzipcode,
	          'email': uemail,
	          'phone': uphone,
	          'state': ustate,
	          'message': umessage,
	        }
			if (!captchaValidated) {
				setDisplayCaptchaError(true)
				return
			}

		


			let contactResults = await axios({
				method: 'post', 
				url: CONTACT_US,
				data: data,
			})

			if (contactResults.data.success) {

				ReactGA.event("Contact Form Submission");

				/* 
					todo :
						Clear form? navigate somewhere else? not sure, but need to do something once seuccessful
				*/
				//toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Contact Us message has been sent.', life: 3000 });
				setPageView('confirmation')
			} else {
				toast.current.show({ severity: 'error', summary: 'Error', detail: 'There was an issue, please try again later.', life: 3000 });
			}
        },
    });

	console.log('Errors', errors)
        
    return (
        <div className="flex flex-column" style={{ width: '100%'}}>
			<Toast ref={toast} />
            
			{pageView === 'contact' && 
				<form onSubmit={handleSubmit} className="submit-question-form">
					<h1>Submit a Question</h1>
					<div className="grid">

						<div className="col-12 md:col-6 xl:col-6 mb-4">
							<span className="p-float-label">
								<InputText
									id="full_name"
									label="Full name"
									value={ufullname} 
									onChange={(e) => setFullName(e.target.value)}
									className="w-12"
								/>
								<label htmlFor="full_name">Full Name*</label>
							</span>
							{submitted && !ufullname && <small className="p-error">Full Name is required</small>}
						</div>

						<div className="col-12 md:col-6 xl:col-6 mb-4">
							<span className="p-float-label">
								<InputText
									id="degree"
									label="Degree"
									value={udegree} 
									onChange={(e) => setDegree(e.target.value)}
									className="w-12"
								/>
								<label htmlFor="degree">Degree*</label>
							</span>
							{submitted && !udegree && <small className="p-error">Degree is required</small>}
						</div>

						<div className="col-12 mb-4">
							<span className="p-float-label">
								<InputText
									id="institution"
									value={uinit} 
									onChange={(e) => setInit(e.target.value)}
									className="w-12"
								/>
								<label htmlFor="institution">Institution/Office*</label>
							</span>
							{submitted && !uinit && <small className="p-error">Institution/Office is required</small>}
						</div>

						<div className="col-12 mb-4">
							<span className="p-float-label">
								<InputText
									id="address"
									value={uaddress} 
									onChange={(e) => setAddress(e.target.value)}
									className="w-12"
								/>
								<label htmlFor="address">Address*</label>
							</span>
							{submitted && !uaddress && <small className="p-error">Address is required</small>}
						</div>

						<div className="col-12 md:col-4 xl:col-4 mb-4">
							<span className="p-float-label">
								<InputText
									id="city"
									value={ucity} 
									onChange={(e) => setCity(e.target.value)}
									className="w-12"
								/>
								<label htmlFor="city">City*</label>
							</span>
							{submitted && !ucity && <small className="p-error">City is required</small>}
						</div>
						<div className="col-12 md:col-4 xl:col-4 mb-4">
							<span className="p-float-label">
								<Dropdown 
									id="state"
									value={ustate}
									onChange={(e) => setState((prev) => (prev = e.value))} 
									options={STATE_DROPDOWN} 
									placeholder="State*" 
									className="w-12" 
								/>
								<label htmlFor="state">State*</label>
							</span>
							{submitted && !ustate && <small className="p-error">State is required</small>}
						</div>
						<div className="col-12 md:col-4 xl:col-4 mb-4">
							<span className="p-float-label">
								<InputNumber
									// {...getFieldProps('zipcode')}
									inputId="zipCode"
									id="zipCode"
									useGrouping={false}
									value={uzipcode} 
									onChange={(e) => setCode(e.value)}
									onBlur={handleBlur}
									className="w-12"
								/>
								<label htmlFor="zipCode">Zip Code*</label>
							</span>
							{submitted && !uzipcode && <small className="p-error">Zip Code is required</small>}
						</div>

						<div className="col-12 md:col-6 xl:col-6 mb-4">
							<span className="p-float-label">
								<InputText
									id="email"
									label="Email"
									value={uemail} 
									onChange={(e) => setEmail(e.target.value)}
									className="w-12"
								/>
								<label htmlFor="email">Email*</label>
							</span>
							{submitted && errors['uemail'] && <small className="p-error">Proper Email is required</small>}
						</div>
						<div className="col-12 md:col-6 xl:col-6 mb-4">
							<span className="p-float-label">
								<InputMask 
									id="phone" 
									mask="(999) 999-9999" 
									className="w-12"
									value={uphone} 
									onChange={(e) => setPhone(e.target.value)} 
								/>
								<label htmlFor="phone">Phone Number*</label>
							</span>
							{submitted && !uphone && <small className="p-error">Phone Number is required</small>}
						</div>

						<div className="col-12 mb-4">
							<div className="flex flex-row flex-wrap">
								<label className="mr-3 mb-3"><b>Would you also like to be contacted by a Medical Science Liaison (MSL)?</b></label>
								{radioButton.map(opt => (
									<div key={opt.value} className="flex mr-3">
										<RadioButton {...getFieldProps('medical')} name="medical" value={opt.value} checked={getFieldProps('medical').value === opt.value}/>
										<label htmlFor={opt.value} className="ml-2">{opt.name}</label>
									</div>
								))}

							</div>
						</div>

						<div className="col-12 mb-4">
							<div className="textarea">
								<label><b>Inquiry (Please be specific):</b></label>
								<p className="note">NOTE: Do not use this form to report Adverse Events or Product Complaints – to do so, please call <b><Link to="tel:+18666963873">(866) 696-3873</Link></b></p>

								<InputTextarea 
									id="description" 
									placeholder="Your Message"
									value={umessage} 
									onChange={(e) => setMessage(e.target.value)} 
									rows={5} cols={30} 
									className="w-12"
									/>
									{submitted && !umessage && <small className="p-error">Message is required</small>}
									
							</div>
						</div>


						<div className="col-12 md:col-6 xl:col-6 mb-4">
							<ReCaptcha 
								fncCaptchaValidated={fncCaptchaValidated}
								displayCaptchaError={displayCaptchaError}
							/>
						</div>

						

						<div className="col-12 md:col-6 xl:col-6 mb-4 text-right submit-form-btn">
							<Button type="submit" label="Submit" />
						</div>
				
					</div>

				</form>
			}
			{pageView === 'confirmation' && 
				<div className="confirmation submission" >
					<h1>Thank You</h1>
                <p>We have received your submission and will review your information as soon as possible.</p>
				</div>
			}
        
        </div>      
    );
}

export default ContactForm;