import React from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import NeurelisIcon from './NeurelisIcon';

const ResourceMenu = (props) => {   
	const { selectedCategory, arrCategories } = props
	const navigate = useNavigate();
	
	const fncNavigate = (curRow) => {
		if (curRow.internal) {
			navigate('/resources' +  curRow.link)
		} else {
			window.open(curRow.link,'_blank')
		}
		
	}
	
	return (
		<div className="flex flex-column subMenu">

			{arrCategories.map((cur, index) => {
				
				const rowClassName = classNames('flex justify-content-between subMenuRow', {
					selected: '/' + selectedCategory === cur.link
				})
				
				return (
					<div key={index} className={rowClassName} onClick={(e) => fncNavigate(cur) }>
						<div className="flex align-items-center" >
							<NeurelisIcon icon={cur.icon} selected={('/' + selectedCategory === cur.link)} />
							<div className="name">{cur.name}</div>
						</div>
						<div className="flex align-items-center openIcon">
							{(cur.internal ? 
								<i className="pi pi-chevron-right" />
							:
								<i className="pi pi-arrow-up-right" />
							)}
						</div>
					</div>
				)
			})}
		</div>
		
    );
}

export default ResourceMenu;