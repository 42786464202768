import React, { useEffect }  from 'react';

// Needed 3rd party libraries
import { useParams, useNavigate    } from 'react-router-dom';

// Prime React Components

// Needed Components / Images / Constants
import { fncGetPDF } from "../services/PDFService"

const View = (props) => {
	let { resourceID } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		fncLoadResource()
    }, [resourceID]); 


	const fncLoadResource = async () => {
		let results = await fncGetPDF(resourceID)
		if (results === 'no-token') {
			alert('you must be logged in')
		} else {
			navigate(-1)
		}
	}
	
	return (
        <div className="flex flex-column">
           VIEW VIEW VIEW {resourceID}
        </div>
    );
}

export default View;